import { createAsyncThunk } from '@reduxjs/toolkit';

export const sendMessageAsyncThunk = createAsyncThunk('sendMessageAsyncThunk', async (messageJSON) => {
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(messageJSON)
  };

  try {
    const fetchResponse = await fetch('https://usa2georgia.com/private_usa_ws/chatbot.php', settings);
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    return e;
  }
});

export const sendFileAsyncThunk = createAsyncThunk('sendFileAsyncThunk', async ({ file, data }) => {
  const settings = {
    method: 'POST',
    'Content-Type': file.type,
    'Content-Length': `${file.size}`,
    body: data
  };

  try {
    const fetchResponse = await fetch('https://usa2georgia.com/private_usa_ws/chatbot.php', settings);
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    return e;
  }
});
