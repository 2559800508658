// hooks
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

// assets
import { BranchesLocationIcon, SettingsIcon, TransactionsIcon } from '../../assets/icons';
import GlobalContext from '../GlobalContext';

const SettingsNav = () => {
  const { t } = useTranslation();

  const footerNav = [
    {
      name: t('_TRANSACTIONS'),
      path: 'transactions',
      icon: <TransactionsIcon />
    },
    {
      name: t('_ADDRESSES'),
      path: 'address',
      icon: <BranchesLocationIcon />
    },
    {
      name: t('_SETTINGS'),
      path: 'settings',
      icon: <SettingsIcon />
    }

    // ,
    // {
    //   name: t('_title_tickets'),
    //   path: 'mails',
    //   icon: <TicketIcon />
    // }
  ];

  const location = useLocation();
  const path = location.pathname.substring(1);

  return (
    <div>
      {footerNav.map((item, index) => (
        <div key={index} className={`d-flex align-items-center mb-4 cursor-pointer ${path === item.path ? 'multiple-colors-active' : ' hover-multiple-colors'}`}>
          <div className='settings-nav-icon-container'>{item.icon}</div>
          <Link to={`/${item.path}`}>
            <span className={` font-roman ${path === item.path ? 'color-white' : 'color-gray hover-white'} ms-3 font-size-14`}>{item.name}</span>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default SettingsNav;
