// hooks
import React, { useEffect } from 'react';

// assets
import { RecycleBinIcon, PenIcon, Arrow, TickIcon } from '../assets/icons';
import useImage from './hooks/useImage';
import { useTranslation } from 'react-i18next';
import useProductIcon from './hooks/useProductIcon';

export default function ChangablePackageDetails({
  icon,
  containerStyle,
  item,
  type,
  title,
  content,
  editable,
  deletable,
  deleteFunction,
  viewable,
  selectedProduct,
  disabled,
  selectedFunction,
  editableFunction,
  onClickPassed,
  titleClick,
  passedFunction,
  iconStyle,
  selected,
  imgStyle,
  src,
  uploadable,
  noFlex,
  showNew,
  productCode,
  ...props
}) {
  const { image } = useImage(title);
  const { productCodeImage } = useProductIcon(productCode);

  const { t } = useTranslation();

  console.log(src, imgStyle, icon);

  return (
    <div
      className={`changableInfo ${onClickPassed ? 'cursor-pointer' : ''} ${containerStyle || ''} ${noFlex ? '' : ' d-flex justify-content-between '} ${selectedProduct || ''} ${
        disabled || ''
      } ${selected ? 'changableInfo-active' : ''} ${type || ''}`}
      onClick={onClickPassed}
      style={{ position: 'relative' }}
    >
      {showNew && item.isNew && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: '#f68424',
            color: 'white',
            padding: '4px 20px',
            fontSize: '11px',
            borderRadius: '0 6px 0 6px'
          }}
        >
          {t('_NEW')}
        </div>
      )}
      <div className={`d-flex ${type === 'invoice' ? 'justify-content-start invoiceContainer' : 'justify-content-between'}`}>
        {!productCode && (src || icon) && (
          <div className='sidebarPackageIcon rounded-circle d-flex align-items-center justify-content-center'>
            <div className={`${iconStyle || ''} ${src ? 'image' : ' image d-flex '}`}>
              {/* {image ? <img src={image} className={imgStyle} alt='Shipper Image' /> : src ? <img src={src} className={imgStyle} alt='Locker Image' /> : icon} */}
              {image !== null ? <img src={image} className={imgStyle} alt='Shipper Image' /> : icon}
            </div>
          </div>
        )}
        {productCode && (
          <div className='sidebarPackageIcon rounded-circle d-flex align-items-center justify-content-center'>
            <div className='d-flex'>{productCodeImage !== null ? <img src={productCodeImage} style={{ width: 18 }} alt='Product Image' /> : icon}</div>
          </div>
        )}
        <div
          className={`d-flex flex-column p-2 w-full overflow-hidden ${content ? 'justify-content-between' : 'justify-content-center'} ${
            type === 'invoice' ? 'invoiceNameDiv' : ''
          }`}
        >
          {type === 'invoice' ? (
            <h6 className={`ChangablePackageDetailsTitle m-0 ${type === 'invoice' ? 'invoiceName' : ''}`} onClick={titleClick}>
              {item}
            </h6>
          ) : (
            <>
              <h6 className='ChangablePackageDetailsTitle m-0'>{title}</h6>
              {content && <span className='fadedContent'>{content}</span>}
            </>
          )}
        </div>
      </div>
      <div className='d-flex justify-content-between '>
        {editable && (
          <div className='d-flex justify-content-center align-items-center actionIcons cursor-pointer relative' onClick={editableFunction}>
            <PenIcon />
            {uploadable && <input type='file' className='uploadInput cursor-pointer' accept='image/png, image/jpeg, image/jpg' multiple={false} {...props} />}
          </div>
        )}
        {deletable && (
          <div className='d-flex justify-content-center align-items-center actionIcons cursor-pointer' onClick={deleteFunction}>
            <RecycleBinIcon />
          </div>
        )}
        {viewable && (
          <div className='d-flex justify-content-center align-items-center actionIcons cursor-pointer' onClick={passedFunction}>
            <Arrow />
          </div>
        )}
        {selectedProduct === 'selectedDiv' && (
          <div className={`d-flex justify-content-center align-items-center actionIcons cursor-pointer tickIconDiv ${deletable ? 'ms-3' : ''}`} onClick={selectedFunction}>
            <TickIcon />
          </div>
        )}
      </div>
    </div>
  );
}
