// hooks
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

// components
import SideBar from '../../../components/sidebar/SideBar';
import SmallIcon from '../../../components/SmallIcon';
import ChangePassword from './ChangePassword';
import NotificationsSettings from './NotificationsSettings';
import ProfileSettings from './ProfileSettings';
import apiRequest from '../../../components/ApiRequest';
import CustomerCards from './CustomerCards';
import Tickets from './tickets/Tickets';
import BranchesAndLockers from './BranchAndLocker/BranchesAndLockers';

// assets
import { Arrow } from '../../../assets/icons';
import CutDownSettings from './Cutdown/CutDownSettings';
import { branches } from '../../../data/branches';

const SettingsItem = ({ title, icon, item, branch, locker }) => {
  const { t } = useTranslation();
  const [hoverIcon, setHoverIcon] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [activeLockerStep, setActiveLockerStep] = useState(0);
  const settingRef = useRef();

  const branchesData = branches;

  const openForm = () => {
    switch (title) {
      case t('_VIEW_PROFILE'):
        return (
          <ProfileSettings userInfo={item} setOpenSettings={setOpenSettings} />
        );
      case t('_CHANGE_PASSWORD'):
        return <ChangePassword setOpenSettings={setOpenSettings} />;
      case t('_NOTIFICATIONS'):
        return (
          <NotificationsSettings
            notifications={item}
            setOpenSettings={setOpenSettings}
          />
        );
      case t('_CUTDOWN_SETTINGS'):
        return (
          <CutDownSettings
            userInfo={item}
            setOpenSettings={setOpenSettings}
          />
        );
      case t('_select_branch_or_locker'):
        return (
          <BranchesAndLockers
            originalBranch={branch}
            setOpenSettings={setOpenSettings}
            branchesData={branchesData}
            locker={locker}
          />
        );
      case t('_SAVED_CARDS'):
        return <CustomerCards setOpenSettings={setOpenSettings} cards={item} />;
      case t('_title_tickets'):
        return <Tickets setOpenSettings={setOpenSettings} />;
      default:
    }
  };

  return (
    <div>
      <div onClick={() => settingRef.current.open()}>
        <div
          onMouseEnter={() => setHoverIcon(true)}
          onMouseLeave={() => setHoverIcon(!hoverIcon)}
          className={'faq-div cursor-pointer mb-2'}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-4">
                <SmallIcon
                  containerStyle="faqlist-icon small-icon-primary-bg "
                  img={icon}
                />
              </span>
              <p className="font-light font-size-14">{title}</p>
            </div>
            <div className="me-3 settings-arrow">
              <span className="arrow-span">
                <Arrow />
              </span>
            </div>
          </div>
        </div>
      </div>
      <SideBar
        activeStep={activeLockerStep}
        setActiveStep={setActiveLockerStep}
        openSidebar={openSettings}
        setOpenSidebar={setOpenSettings}
        ref={settingRef}
      >
        {openForm()}
      </SideBar>
    </div>
  );
};

export default SettingsItem;
