// hooks
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';

// components
import OrangeLine from '../../components/OrangeLine';
import apiRequest from '../../components/ApiRequest';

// assets
import { FlightArrivedIcon, FlightProgressIcon } from '../../assets/icons';

// libraries
import GlobalContext from '../../components/GlobalContext';
import Wrapper from '../../components/Wrapper';

const FlightsSchedule = () => {
  const { lang } = useParams();
  const { setShowLoader } = useContext(GlobalContext);
  const { t } = useTranslation();
  const currentLang = i18n.language;

  const {
    data: FlightsScheduleData,
    isLoading,
    isSuccess
  } = useQuery('FlightsScheduleData', () =>
    apiRequest({
      command: 'get_hawb_list_ui'
    })
  );

  useEffect(() => {
    if (['ka', 'en'].includes(lang)) {
      i18n.changeLanguage(lang);
    }
    setShowLoader(isLoading);
  }, [isLoading]);

  const flightsList = isSuccess && FlightsScheduleData.data.result;

  return (
    <Wrapper>
      <div className='flights-main-container'>
        <div className='mt-5'>
          <OrangeLine />
        </div>
        <h1 className='mb-5'> {t('_FLIGHTS_SCHEDULE')} </h1>
        {isSuccess &&
          flightsList.map((item, index) => (
            <div key={index} className='flights-container'>
              <div
                className={`why-usa2-icon d-flex align-items-center justify-content-center me-4 ${
                  item?.comments[currentLang].includes('ჩამოვიდა') || item?.comments[currentLang].includes('Arrived') ? ' flight-arrived' : 'flight-progress'
                }`}
              >
                {item?.comments[currentLang].includes('ჩამოვიდა') || item?.comments[currentLang].includes('Arrived') ? <FlightArrivedIcon /> : <FlightProgressIcon />}
              </div>
              <div className='d-flex font-light secondary-grey flex-column'>
                <div className='font-size-14 font-bold-caps secondary-grey mb-3' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <p>
                    {item?.hawbdate[currentLang]} - {item.hawb}
                    {item.proxy_country ? <span style={{ color: 'black', marginLeft: 10 }}>{item.proxy_country}</span> : <></>}
                  </p>
                  <img height='30px' width={'30px'} src={require('../../assets/images/flags/' + `${item.proxy_country ? item.proxy_country : item.country}.svg`)} />
                </div>
                <p className='font-size-14 color-gray'>{item?.comments[currentLang]}</p>
              </div>
            </div>
          ))}
      </div>
    </Wrapper>
  );
};

export default FlightsSchedule;
