import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import OrangeLine from '../OrangeLine';
import GlobalContext from '../GlobalContext';
import LoginedUserNavbar from './LoginedUserNavbar';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { HashLink } from 'react-router-hash-link';
import SearchIcon from '../../assets/icons/searchMenu.svg';

export default function Navbar() {
  const { t } = useTranslation();
  const currentLang = i18n.language;
  const { token, setShowCalculator } = useContext(GlobalContext);
  const location = useLocation();
  const path = location.pathname.substring(1);
  const [activeHome, setActiveHome] = useState('');
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickDocument(event) {
      setSearchString('');
    }

    document.addEventListener('mousedown', handleClickDocument);
    return () => {
      document.removeEventListener('mousedown', handleClickDocument);
    };
  }, []);

  useEffect(() => {
    const homepagePaths = ['', 'auth', 'warehouse', 'pending', 'arrived', 'received'];
    if (homepagePaths.includes(path)) {
      setActiveHome(true);
    } else {
      setActiveHome(false);
    }
  }, [path]);

  return (
    <div className='p-0'>
      <div className={token ? 'd-flex responsive-hidden flex-row justify-content-between' : null}>
        <ul className='navbar-div'>
          <li className='navbar-li mb-2'>
            <Link to='/'>
              <p className={`navbar-li-p font-size-14 ${activeHome && 'navbar-li-active'}`}>{t('_SITE_HOME')}</p>
            </Link>
            {activeHome && <OrangeLine />}
          </li>
          <li className='navbar-li mb-2'>
            <Link to='/faq'>
              <p className={`font-size-14 navbar-li-p ${path === 'faq' && 'navbar-li-active'}`}>{t('_FAQ')}</p>
            </Link>
            {path === 'faq' && <OrangeLine />}
          </li>
          <li className='navbar-li mb-2'>
            <a href={`https://news.usa2georgia.com/${i18n.language === 'ka' ? '' : i18n.language}`} target='_blank' rel='noopener noreferrer'>
              <p className='font-size-14 navbar-li-p'>{t('_BLOG_LINK')}</p>
            </a>
          </li>
          {token ? (
            <li className='navbar-li mb-2'>
              <p
                className='font-size-14 navbar-li-p cursor-pointer'
                onClick={() => {
                  setShowCalculator(true);
                }}
              >
                {t('_PRICES')}
              </p>
            </li>
          ) : (
            <li className='navbar-li mb-2'>
              <HashLink to='/#prices'>
                <p className='font-size-14 navbar-li-p'>{t('_PRICES')}</p>
              </HashLink>
            </li>
          )}
          <li className='navbar-li mb-2'>
            <Link to={'/flights-schedule/' + i18n.language}>
              <p className={`font-size-14 navbar-li-p ${path.startsWith('flights-schedule') && 'navbar-li-active'}`}>{t('_FLIGHTS')}</p>
            </Link>
            {path.startsWith('flights-schedule') && <OrangeLine />}
          </li>
          <li className='navbar-li mb-2'>
            <Link to={'/branches/' + i18n.language}>
              <p className={`navbar-li-p font-size-14 ${path.startsWith('branches') && 'navbar-li-active'}`}>{t('_BRANCH_SETTINGS')}</p>
            </Link>
            {path.startsWith('branches') && <OrangeLine />}
          </li>
          <li className='navbar-li mb-2' style={{ position: 'relative' }}>
            {/* <Link to={'/track-package/' + i18n.language}> */}
            {/* <p className={`navbar-li-p font-size-14 ${path === 'find-package' && 'navbar-li-active'}`}>{t('_TRACK_PACKAGE')}</p> */}
            {/* <img src={SearchIcon} style={{ fill: 'red', color: 'red' }} /> */}

            {/* </Link> */}
            {path.startsWith('track-package') ? (
              <div style={{ position: 'absolute', right: 0 }}>
                <div>
                  <label className='s-button s-searchbutton' htmlFor='searchright'>
                    <img src={SearchIcon} />
                  </label>
                </div>
              </div>
            ) : (
              <div style={{ position: 'absolute', right: 0 }}>
                <div>
                  <input
                    className='s-search s-expandright'
                    id='searchright'
                    type='text'
                    placeholder={t('_TRACKING')}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && searchString.length > 0) {
                        navigate('/track-package/' + i18n.language + '/' + searchString);
                      }
                    }}
                  />
                  <label className='s-button s-searchbutton' htmlFor='searchright'>
                    <img src={SearchIcon} />
                  </label>
                </div>
              </div>
            )}
          </li>
          {/* <li className='navbar-li mb-2'>
            <Link to='/help'>
              <p className={`font-size-14 navbar-li-p ${path === 'help' && 'navbar-li-active'}`}>{t('_HELP')}</p>
            </Link>
            {path.includes('help') && <OrangeLine />}
          </li> */}
        </ul>
        {token && <LoginedUserNavbar />}
      </div>
    </div>
  );
}
