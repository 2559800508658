import { createSlice } from '@reduxjs/toolkit';
import { getCustomerInfoAsyncThunk } from '../thunks/customer.thunk';

const initialState = {
  customerId: null,
  panelLanguage: null,
  firstName: null,
  lastName: null,
  fullName: null,
  avatar: null,
  branchSelection: [],
  confirmation: null,
  confirmationData: null,
  notification: null,
  flags: {
    needsUploadIdentification: false,
    nonGeorgianCitizen: false
  },
  settings: {
    language: 'ka',
    address: {
      lat: 0, // 41.722948,
      lng: 0, // 44.721924,
      comment: ''
    },
    cutdown: {
      automatic: false
    }
  }
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setAutomaticCutdownSetting: (state, action) => {
      state.settings.cutdown.automatic = action.payload;
    },
    setAddressSetting: (state, action) => {
      state.settings.address.lat = parseFloat(action.payload.lat);
      state.settings.address.lng = parseFloat(action.payload.lng);
      state.settings.address.comment = action.payload.comment;
    },
    setNeedsUploadIdentification: (state, action) => {
      state.flags.needsUploadIdentification = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerInfoAsyncThunk.pending, (state) => {})
      .addCase(getCustomerInfoAsyncThunk.fulfilled, (state, action) => {
        if (action.payload.status === 'OK') {
          state.settings.cutdown.automatic = action.payload.result.profile.customer_settings.PARAM_CUT_DOWN === '1';

          state.customerId = parseInt(action.payload.result?.profile?.customer_id);
          state.panelLanguage = action.payload.result?.profile?.panel_language;
          state.fullName =
            action.payload.result?.profile?.panel_language === 'ka'
              ? action.payload.result?.profile?.first_name_ka + ' ' + action.payload.result?.profile?.last_name_ka
              : action.payload.result?.profile?.first_name_en + ' ' + action.payload.result?.profile?.last_name_en;

          state.firstName = action.payload.result?.profile?.panel_language === 'ka' ? action.payload.result?.profile?.first_name_ka : action.payload.result?.profile?.first_name_en;
          state.lastName = action.payload.result?.profile?.panel_language === 'ka' ? action.payload.result?.profile?.last_name_ka : action.payload.result?.profile?.last_name_en;

          state.avatar = action.payload.result?.profile?.avatar;

          state.branchSelection = action.payload.result?.branch_selection;
          if (action.payload?.result?.profile?.piradoba === 0 && action.payload?.result?.profile?.hasComingPackages > 0) {
            state.flags.needsUploadIdentification = true;
          }
          state.flags.nonGeorgianCitizen = action.payload?.result?.profile?.non_georgian_citizen === 1;
          state.notification = action.payload?.result?.profile?.notifications;
          if (action.payload?.result?.profile?.confirmation?.id === '11') {
            state.confirmation = action.payload?.result?.profile?.confirmation;
            state.confirmationData = JSON.parse(action.payload?.result?.profile?.confirmation?.message);
          } else {
            state.confirmation = action.payload?.result?.profile?.confirmation;
          }
        }
      });
  }
});

export const customerSliceActions = customerSlice.actions;
export default customerSlice.reducer;
