import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://usa2georgia.com/private_usa_ws/rest.php'
});

const toQueryString = (obj) => {
  const parts = [];
  for (const i in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(i)) {
      parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
    }
  }
  return parts.join('&');
};

export const sendGetRequest = (instance, params) => {
  return instance.get(`?secret=EFDCF20F32-B233EA-45DF75-B01E-8098A066&sessionId=${window.localStorage.getItem('token')}&${toQueryString(params)}`).then(res => res.data);
};

export const sendPostRequest = (instance, params) => {
  return instance.post('', {
    secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
    sessionId: window.localStorage.getItem('token'),
    ...params
  }).then(res => {
    if (res.data?.code === 102) {
      window.localStorage.removeItem('token');
    }
    return res.data;
  });
};
