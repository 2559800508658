// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';

// components
import Button from '../../components/Button';
import SmallIcon from '../../components/SmallIcon';
import apiRequest from '../../components/ApiRequest';
import SettingsNav from '../../components/navigation/SettingsNav';
import SideBar from '../../components/sidebar/SideBar';
import FillBalance from '../sidebar-pages/FillBalance';
import GlobalContext from '../../components/GlobalContext';
import LockerDetails from '../../components/LockerDetails';

// assets
import { BarcodeIcon } from '../../assets/icons';
import ResponsiveCategories from '../../components/navigation/responsive/ResponsiveCategories';
import Code128Generator from 'code-128-encoder';
import Modal from '../../components/modal/Modal';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import UserIcon from '../../assets/images/user-icon-white.svg';
import agreementFunction from '../../components/agreementFunction';
import ProfileSettings from '../settings/components/ProfileSettings';

export default function LoginedUser() {
  const [openBalanceSidebar, setOpenBalanceSidebar] = useState(false);
  const [fullNameKa, setFullNameKa] = useState(null);
  const [fullNameEn, setFullNameEn] = useState(null);
  const balanceSidebarRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token, setToken, setCustomerId, setGetCustomerInfo, setShowLoader } = useContext(GlobalContext);
  const currentLang = i18n.language;

  useEffect(() => {
    // console.log('LoginedUser.js - get_customer_info');
  }, []);

  const {
    data: getSettings,
    isSuccess: isGetSettingsSuccess
  } = useQuery('getCustomerSettings', () =>
    apiRequest({
      command: 'get_customer_settings',
      sessionId: localStorage.getItem('token')
    })
  );

  const { data: userData, isSuccess } = useQuery(
    'userInfo',
    () =>
      apiRequest({
        command: 'get_customer_info',
        sessionId: token
      }),
    {
      onSuccess: (response) => {
        agreementFunction(response, navigate);
        if (response.data.status === 'FAIL') {
          localStorage.removeItem('token');
          setToken(null);
          navigate('/');
        }
        setCustomerId(response.data.result?.profile?.customer_id);
        setGetCustomerInfo(response.data.result);
        // localStorage.setItem('selectedLanguage', response.data.result?.profile?.panel_language);
        // console.log(response.data.result?.profile?.panel_language);
        i18n.changeLanguage(response.data.result?.profile?.panel_language);

        if (response.data.status === 'OK' && response.data.result?.profile?.notifications !== null) {
          setShowLoader(false);
          setToken(token);
          navigate('/pop_notifications');
        }
      }
    }
  );

  const balanceInfo = isSuccess && userData.data.result?.profile.balance_info;
  const userInfo = isSuccess && userData.data.result?.profile;
  const barCode = isSuccess && userData.data.result?.profile.sms_code;
  const profile = isSuccess && userData.data.result?.profile;
  const userProfile = isGetSettingsSuccess && getSettings.data.result.data.profile;
  
  const [showModal, setShowModal] = useState(false);
  const encoder = new Code128Generator();

  const [openSettings, setOpenSettings] = useState(false);

  useEffect(() => {
    if (userInfo) {
      // foreigner
      if (userInfo.non_georgian_citizen === 1) {
        setFullNameEn(`${userInfo.first_name} ${userInfo.last_name}`);
        setFullNameKa(`${userInfo.first_name} ${userInfo.last_name}`);
        // georgian
      } else {
        setFullNameEn(`${userInfo.first_name_en} ${userInfo.last_name_en}`);
        setFullNameKa(`${userInfo.first_name_ka} ${userInfo.last_name_ka}`);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (localStorage.getItem('savedEmail') && userData && profile && !localStorage.getItem('userKa')) {
      if (profile.non_georgian_citizen === 1) {
        localStorage.setItem('userKa', `${profile.first_name_en} ${profile.last_name_en}`);
      } else {
        localStorage.setItem('userKa', `${profile.first_name_ka} ${profile.last_name_ka}`);
      }
      localStorage.setItem('userEn', `${profile.first_name_en} ${profile.last_name_en}`);
      if (profile.avatar) {
        localStorage.setItem('avatar', profile.avatar.file);
        localStorage.setItem('extention', profile.avatar.extention);
      }
    }
  }, [userData]);

  return (
    <div>
      {openSettings && (
        <SideBar openSidebar={openSettings} setOpenSidebar={setOpenSettings}>
          <ProfileSettings userInfo={userProfile} setOpenSettings={setOpenSettings} />
        </SideBar>
      )}
      <div className='d-flex flex-row my-3 justify-content-between align-items-center'>
        <div className='user-box'>
          {profile?.avatar?.file ? (
            // <Link to='/settings' className='img'>
            <div className='img' style={{ cursor: 'pointer' }}>
              <img
                onClick={() => {
                  setOpenSettings(true);
                }}
                src={`data:image/${profile?.avatar?.extention};base64, ${profile?.avatar?.file}`}
                alt=''
                className='mCS_img_loaded'
              />
            </div>
          ) : (
            // </Link>
            <Link to='/settings' className='img empty'>
              <div className='avatar empty'>
                <img src={UserIcon} alt='user-icon' />
              </div>
            </Link>
          )}
        </div>
        <div className='d-flex justify-content-between w-full'>
          {userInfo && (
            <div>
              <p className='font-size-16 font-light color-white'>{currentLang === 'en' ? fullNameEn : fullNameKa}</p>
              <p className='font-size-14 color-gray'>
                {t('_SUITE')}: <span className='font-bold'>{userInfo.suite}</span>
              </p>
            </div>
          )}
        </div>
        {barCode && (
          <div className='barcode-container' style={{ flexGrow: 1 }}>
            <div
              onClick={() => setShowModal(true)}
              className='small-icon-div ms-5 cursor-pointer position-relative d-flex justify-content-center align-items-center bg-dark float-right'
            >
              <SmallIcon img={<BarcodeIcon />} />
            </div>
          </div>
        )}
      </div>
      <div className='mt-3 d-flex justify-content-between'>
        <div className='icon-dark-bg balance-container w-full d-flex flex-column align-items-center px-2 border-rad-12'>
          <p className='font-size-14 mb-2 text-center color-gray'>{t('_UNUSED_CREDITS')}</p>
          <p className='font-size-14 mb-3 font-bold text-center color-white'>{balanceInfo && balanceInfo.balance.toFixed(2)} GEL</p>
          <div className='w-full' onClick={() => balanceSidebarRef.current.open()}>
            <Button btnStyle='bg-orange fill-balance-btn' text={t('_CARDS_LIST_NAVIGATION_TITLE')} color='color-white' />
          </div>
          <SideBar openSidebar={openBalanceSidebar} setOpenSidebar={setOpenBalanceSidebar} ref={balanceSidebarRef}>
            <FillBalance openSidebar={openBalanceSidebar} setOpenSidebar={setOpenBalanceSidebar} priceAmount={userData?.data?.result?.profile?.balance_info?.payable} />
          </SideBar>
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center w-full'>
          <p className='color-gray mb-2 font-size-12'> {t('_AMOUNT_DUE')}</p>
          <p className={`${balanceInfo?.payable > 0 ? 'color-red' : 'color-green'}  font-bold text-center`}>
            <strong>{balanceInfo && balanceInfo.payable.toFixed(2)} GEL</strong>
          </p>
        </div>
      </div>
      <div>
        <ResponsiveCategories />
      </div>
      <LockerDetails />
      <div className='mt-5 responsive-hidden'>
        <SettingsNav />
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {barCode && (
          <div className='barcode-container'>
            <p className='barcode-title'>{barCode}</p>
            <div className='parent-128'>
              <div className='code128'>{encoder.encode(barCode)}</div>
            </div>
            <div>
              <h4 className='text-center'>
                USA2GEORGIA <br />
                {t('_TERMINAL_CODE')}
              </h4>
            </div>
            <Button handleClick={() => setShowModal(false)} type='button' btnStyle='bg-orange fill-balance-btn' text={t('_CLOSE')} color='color-white' />
          </div>
        )}
      </Modal>
    </div>
  );
}
