// hooks
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// assets
import { ArrivedPackagesIcon, BarcodeIcon, CloseModalIcon, CoinIcon, FaqIcon, HomeIcon, LogoutIcon, MultipleLocationsIcon, NewsIcon, QuestionIcon } from '../../../assets/icons';
import En from '../../../assets/images/enFlag.png';
import Ka from '../../../assets/images/kaFlag.png';

// components
import SettingsNav from '../SettingsNav';
import Locker from '../../LockerDetails';
import Button from '../../Button';
import apiRequest from '../../ApiRequest';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router';

const BurgerMenu = ({ showBurgerMenu, setShowBurgerMenu }) => {
  const { token, setToken, setShowCalculator } = useContext(GlobalContext);
  const currentLang = i18n.language;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleChangeLang = (lang) => {
    apiRequest(
      {
        command: 'change_language',
        sessionId: token
      },
      {
        language: lang
      }
    );
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    handleChangeLang(value);
    if (window.location.href.endsWith('/en') || window.location.href.endsWith('/ka')) {
      const newUrl = window.location.href.replace('/en', '').replace('/ka', '');
      window.history.replaceState(null, '', newUrl + '/' + i18n.language);
    }
  };

  const navbarList = [
    {
      name: t('_SITE_HOME'),
      icon: <HomeIcon />,
      path: '',
      href: null
    },
    {
      name: t('_BRANCH_SETTINGS'),
      icon: <MultipleLocationsIcon />,
      path: 'branches/' + i18n.language,
      href: null
    },
    {
      name: t('_FAQ'),
      icon: <QuestionIcon />,
      path: 'faq',
      href: null
    },
    {
      name: t('_BLOG_LINK'),
      icon: <NewsIcon style={{ width: 20, height: 20 }} />,
      path: null,
      href: `https://news.usa2georgia.com/${currentLang === 'ka' ? '' : currentLang}`
    },
    {
      name: t('_PRICES'),
      icon: <CoinIcon style={{ width: 20, height: 20 }} />,
      path: null,
      href: null,
      id: 'price-calculator',
      hash: '#prices'
    },
    {
      name: t('_FLIGHTS'),
      icon: <ArrivedPackagesIcon />,
      path: 'flights-schedule/' + i18n.language,
      href: null
    },
    {
      name: t('_TRACK_PACKAGE'),
      icon: (
        <div className='barcode-burgermenu'>
          <BarcodeIcon />
        </div>
      ),
      path: 'track-package/' + i18n.language,
      href: null
    }
  ];

  const logout = useMutation(
    () =>
      apiRequest({
        command: 'logout',
        sessionId: token
      }),
    {
      onSuccess: () => {
        localStorage.removeItem('token');
        queryClient.clear();
        setToken(null);
        setShowBurgerMenu(false);
        navigate('/');

        document.querySelectorAll('.kommunicate-script').forEach((item) => item.remove());
        const kommunicateIframe = document.getElementById('kommunicate-widget-iframe');
        if (kommunicateIframe) {
          kommunicateIframe.remove();
        }
      }
    }
  );

  return (
    <div className={'burger-menu-container ' + showBurgerMenu}>
      <div className='d-flex justify-content-between mb-3 align-items-center'>
        <div className='lang-switch-div cursor-pointer responsive-show'>
          {currentLang === 'en' ? (
            <div className='d-flex align-items-center' onClick={() => changeLanguage('ka')}>
              <img src={Ka} alt='Ka Flag' className='FlagImage' />
              <span className='color-white lang-switch-text'>KA</span>
            </div>
          ) : (
            <div className='d-flex align-items-center' onClick={() => changeLanguage('en')}>
              <img src={En} alt='En Flag' className='FlagImage' />
              <span className='color-white lang-switch-text'>EN</span>
            </div>
          )}
        </div>
        <div className='burger-menu-btn close-responsive-burger' onClick={() => setShowBurgerMenu(false)}>
          <CloseModalIcon />
        </div>
      </div>
      <ul className='mt-4'>
        {navbarList.map((item, index) => {
          if (item.href !== null) {
            return (
              <li onClick={() => setShowBurgerMenu(false)} key={index}>
                <a href={`${item.href}`} target='_blank' rel='noopener noreferrer'>
                  <div className='d-flex'>
                    <div className='d-flex justify-content-center burger-icons-div'>
                      <div>{item.icon}</div>
                    </div>
                    <p className='color-gray font-size-14 pt-1 mb-3'>{item.name}</p>
                  </div>
                </a>
              </li>
            );
          } else if (item.path !== null) {
            return (
              <li onClick={() => setShowBurgerMenu(false)} key={index}>
                <Link to={`/${item.path}`}>
                  <div className='d-flex'>
                    <div className='d-flex justify-content-center burger-icons-div'>
                      <div>{item.icon}</div>
                    </div>
                    <p className='color-gray font-size-14 pt-1 mb-3'>{item.name}</p>
                  </div>
                </Link>
              </li>
            );
          } else {
            if (item.hash && !token) {
              return (
                <li onClick={() => setShowBurgerMenu(false)} key={index}>
                  <HashLink to={`/${item.hash}`}>
                    <div className='d-flex'>
                      <div className='d-flex justify-content-center burger-icons-div'>
                        <div>{item.icon}</div>
                      </div>
                      <p className='color-gray font-size-14 pt-1 mb-3'>{item.name}</p>
                    </div>
                  </HashLink>
                </li>
              );
            } else {
              return (
                <li
                  onClick={() => {
                    setShowBurgerMenu(false);
                    if (item.id === 'price-calculator') {
                      setShowCalculator(true);
                    }
                  }}
                  key={index}
                  style={{ cursor: 'pointer' }}
                >
                  <div className='d-flex'>
                    <div className='d-flex justify-content-center burger-icons-div'>
                      <div>{item.icon}</div>
                    </div>
                    <p className='color-gray font-size-14 pt-1 mb-3'>{item.name}</p>
                  </div>
                </li>
              );
            }
          }
        })}
      </ul>
      {token && (
        <div>
          <SettingsNav setShowBurgerMenu={setShowBurgerMenu} />
          <Locker />
          <div className='mt-4'>
            <Button icon={<LogoutIcon />} handleClick={logout.mutate} btnStyle='bg-orange logout-btn' text={t('_LOGOUT')} color='color-white' />
          </div>
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
