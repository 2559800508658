import React from 'react';

const UserPolicy = () => {
  return (
    <div className="user-policy-container">
      <iframe
        src='https://usa2georgia.com/agreements/user-policy.html'
      />
    </div>
  );
};

export default UserPolicy;
