/* eslint-disable indent */
// hooks
import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

// components
import { Link } from 'react-router-dom';
import Login from '../pages/auth/Login';
import Navbar from './navigation/Navbar';
import { Container } from 'react-bootstrap';
import LoginedUser from '../pages/auth/LoginedUser';
import GlobalContext from './GlobalContext';
import ResponsiveNavbar from './navigation/responsive/ResponsiveNavbar';
import TermsAndSocial from './TermsAndSocial';
import Button from './Button';
import SmallIcon from './SmallIcon';
import FooterResponsive from './navigation/responsive/FooterResponsive';
import apiRequest from './ApiRequest';

// assets
import En from '../assets/images/enFlag.png';
import Ka from '../assets/images/kaFlag.png';
import { CloseModalIcon, Usa2Icon } from '../assets/icons';
import AppLogo from '../assets/images/usa_app_logo.png';


export default function Wrapper({ children }) {
  const [showSuggestion, setShowSuggestion] = useState('app-suggest');
  const { t } = useTranslation();
  const { isSidebarOpened } = useContext(GlobalContext);
  const { token } = useContext(GlobalContext);
  const [selectedLang, setselectedLang] = useState(localStorage.getItem('i18nextLng'));
  const [deviceType, setDeviceType] = useState(null);

  const handleChangeLang = (lang) => {
    apiRequest({
      command: 'change_language',
      sessionId: token
    },
      {
        language: lang
      }
    );
  };

  const changeLanguage = (lang) => {
    if (token) {
      handleChangeLang(lang);
    }
    i18n.changeLanguage(lang);
    if (window.location.href.endsWith('/en') || window.location.href.endsWith('/ka')) {
      const newUrl = window.location.href.replace('/en', '').replace('/ka', '');
      window.history.replaceState(null, '', newUrl + '/' + i18n.language);
    }
  };

  // useEffect(() => {
  //   // console.log(selectedLang, localStorage.getItem('i18nextLng'));
  //   if (selectedLang !== localStorage.getItem('i18nextLng')) {
  //     // changeLanguage();
  //   }
  // }, [selectedLang]);

  // const checkIfAppInstalled = () => {
  //   const now = new Date().valueOf();
  //   setTimeout(function() {
  //     if (new Date().valueOf() - now > 100) return;
  //     window.location = 'https://apps.apple.com/us/app/usa2georgia/id1461461537';
  //   }, 25);
  //   window.location = 'appname://usa2georgia';
  // };

  useEffect(() => {
    if (/android/i.test(navigator.userAgent)) {
      setDeviceType('android');
    }
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      setDeviceType('ios');
    }
  }, []);

  return (
    <>
      <div className={showSuggestion}>
        <SmallIcon
          handleClick={() => setShowSuggestion('hidden')}
          imgStyle="app-icon"
          img={<CloseModalIcon />}
        />
        <div className='flex inner-div w-full flex-row'>
          <img className='mr-4' src={AppLogo} alt="usaAppLogo" />
          <div className='mt-1'>
            <p>USA2GEORGIA</p>
            <p className='app-text'>{t('_APP_TEXT')}</p>
          </div>
        </div>
        <a
          target="_blank"
          href={deviceType === 'ios' ? 'https://apps.apple.com/us/app/usa2georgia/id1461461537' : 'https://play.google.com/store/apps/details?id=com.usa2georgia.usa2georgia&hl=en&gl=US'}
          rel="noreferrer"
        >
          <Button
            type="button"
            btnStyle="bg-orange app-btn"
            text={t('_DOWNLOAD')}
            color="color-white"
          />
        </a>
      </div>
      <Container fluid className="p-0">
        <div className="screen-comp-left-container d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between align-items-center logo-lang-div">
            <div>
              <Link to="/">
                <Usa2Icon />
              </Link>
            </div>
            <div className="lang-switch-div cursor-pointer responsive-hidden">
              {localStorage.getItem('i18nextLng') === 'en' ? (
                <div
                  className="d-flex align-items-center"
                  onClick={() => changeLanguage('ka')}
                >
                  <img src={Ka} alt="Ka Flag" className="FlagImage" />
                  <span className="color-white lang-switch-text">KA</span>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center"
                  onClick={() => changeLanguage('en')}
                >
                  <img src={En} alt="En Flag" className="FlagImage" />
                  <span className="color-white lang-switch-text">EN</span>
                </div>
              )}
            </div>
            <ResponsiveNavbar />
          </div>
          {token && localStorage.getItem('token') ? <LoginedUser /> : <Login />}
          <TermsAndSocial />
        </div>
        <div
          className={`screen-comp-right-container ${isSidebarOpened ? 'wrapper-overflow-disable' : ''
            }`}
        >
          <div className="responsive-hidden">
            <Navbar />
          </div>
          {children}
        </div>
      </Container>
      <FooterResponsive />
    </>
  );
}
