import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import chatbotSlice from './slices/chatbot.slice';
import customerSlice from './slices/customer.slice';
import packagesSlice from './slices/packages.slice';

const rootReducer = combineReducers({
  customer: customerSlice,
  packages: packagesSlice,
  chatbot: chatbotSlice
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer
  });
};
