import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiRequest from '../../components/ApiRequest';
import Button from '../../components/Button';
import GlobalContext from '../../components/GlobalContext';
import CustomInput from '../../components/customInput/CustomInput';
import Radio from '../../components/radio/Radio';
import USAIcon from './../../assets/images/flags/DELAWARE.svg';
import TurkeyIcon from './../../assets/images/flags/TURKEY.svg';
import ChinaIcon from './../../assets/images/flags/CHINA.svg';
import PriceCalculatorNewCountry from './PriceCalculatorNewCountry';

function PriceCalculatorNew() {
  const [isLoading, setIsLoading] = useState(false);
  const lengthInputRef = useRef();
  const { t } = useTranslation();

  const [hasResult, setHasResult] = useState(true);

  const { setAlert } = useContext(GlobalContext);

  const [country, setCountry] = useState('USA');

  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [cost, setCost] = useState('');

  const [lengthError, setLengthError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [costError, setCostError] = useState(false);

  const clearErrors = () => {
    setLengthError(false);
    setWidthError(false);
    setHeightError(false);
    setWeightError(false);
    setCostError(false);
    setResult({
      realWeight: 0,
      dimWeight: 0,
      chargeableWeight: 0,
      transportation: 0,
      insurance: 0,
      total: 0
    });
  };

  const [metric, setMetric] = useState(true);
  const [box, setBox] = useState(true);

  const [result, setResult] = useState({
    realWeight: 0,
    dimWeight: 0,
    chargeableWeight: 0,
    transportation: 0,
    insurance: 0,
    total: 0
  });

  useEffect(() => {
    const timer = setTimeout(() => lengthInputRef.current?.focus(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSubmit = (e) => {
    // Validation
    let isError = false;
    if (box) {
      if (isNaN(parseFloat(length))) {
        setLengthError(true);
        isError = true;
      }
      if (isNaN(parseFloat(width))) {
        setWidthError(true);
        isError = true;
      }
      if (isNaN(parseFloat(height))) {
        setHeightError(true);
        isError = true;
      }
    }

    if (isNaN(parseFloat(weight))) {
      setWeightError(true);
      isError = true;
    }
    if (isNaN(parseFloat(cost))) {
      setCostError(true);
      isError = true;
    }

    if (isError) {
      setAlert(['error', t('_INVALID_PARAMETERS')]);
      return;
    }

    setIsLoading(true);
    e.preventDefault();
    apiRequest(
      {
        command: 'calculate_chargeable',
        method: 'POST'
      },
      {
        weight,
        length,
        width,
        height,
        amount: cost,
        country: country === 'USA' ? 'DELAWARE' : country,
        metric: metric ? 1 : 0
      }
    ).then(({ data }) => {
      setIsLoading(false);
      if (data?.status === 'FAIL') {
        if (data?.result?.includes('length')) {
          setLengthError(true);
        }
        if (data?.result?.includes('width')) {
          setWidthError(true);
        }
        if (data?.result?.includes('height')) {
          setHeightError(true);
        }
        if (data?.result?.includes('weight')) {
          setWeightError(true);
        }
        if (data?.result?.includes('amount')) {
          setCostError(true);
        }
        setAlert(['error', t(data.message)]);
      } else {
        setHasResult(true);
        setResult(data?.result);
      }
    });
  };

  const tabData = [
    { country: 'USA', isMetric: false, icon: USAIcon },
    { country: 'Turkey', isMetric: false, icon: TurkeyIcon },
    { country: 'China', isMetric: false, icon: ChinaIcon }
  ];

  return (
    <div className='d-flex flex-column height-100 justify-content-start sideBarChildComponent'>
      <h5 className='font-size-14 mb-0'>{t('_PRICE_CALCULATOR')}</h5>
      <div className='sidebar-child-scroll'>
        <div className='pr-60 mt-2'>
          <div style={{ display: 'flex', flexGrow: 0, justifyContent: 'space-around', border: '1px solid rgb(221, 221, 221)', borderBottom: 0, borderRadius: '6px 6px 0 0' }}>
            {tabData.map((element, index) => {
              return <PriceCalculatorNewCountry key={index} index={index} item={element} country={country} setCountry={setCountry} clearErrors={clearErrors} />;
            })}
          </div>
          <div className='border' style={{ padding: '10px', marginBottom: '10px', borderRadius: '0 0 6px 6px' }}>
            <div className='mt-4 w-full'>
              <div className='d-flex justify-content-center'>
                <div className='d-flex flex-column mb-3 flex-1'>
                  {country === 'USA' && (
                    <div className='d-flex mb-3 align-items-center'>
                      <Radio
                        onChange={() => {
                          setMetric(true);
                          clearErrors();
                        }}
                        selected={metric}
                      />
                      <span
                        className='cursor-pointer font-14 user-select-none'
                        onClick={() => {
                          setMetric(true);
                          clearErrors();
                        }}
                      >
                        {t('_METRIC')}
                      </span>
                    </div>
                  )}

                  <div className='d-flex align-items-center'>
                    <Radio
                      onChange={() => {
                        setBox(true);
                        clearErrors();
                      }}
                      selected={box}
                    />
                    <span
                      className='cursor-pointer font-14 user-select-none'
                      onClick={() => {
                        setBox(true);
                        clearErrors();
                      }}
                    >
                      {t('_BOX')}
                    </span>
                  </div>
                </div>

                <div className='d-flex flex-1 flex-column justify-content-between mb-4'>
                  {country === 'USA' && (
                    <div className='d-flex mb-3 align-items-center'>
                      <Radio
                        onChange={() => {
                          setMetric(false);
                          clearErrors();
                        }}
                        selected={!metric}
                      />
                      <span
                        className='cursor-pointer font-14 user-select-none'
                        onClick={() => {
                          setMetric(false);
                          clearErrors();
                        }}
                      >
                        {t('_IMPERIAL')}
                      </span>
                    </div>
                  )}
                  <div className='d-flex align-items-center'>
                    <Radio
                      onChange={() => {
                        setBox(false);
                        setLength(0);
                        setWidth(0);
                        setHeight(0);
                        clearErrors();
                      }}
                      selected={!box}
                    />
                    <span
                      className='cursor-pointer font-14 user-select-none'
                      onClick={() => {
                        setBox(false);
                        setLength(0);
                        setWidth(0);
                        setHeight(0);
                       
                        clearErrors();
                      }}
                    >
                      {t('_BAG')}
                    </span>
                  </div>
                </div>
              </div>
              {box && (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center'
                  }}
                >
                  <CustomInput
                    containerClass='pe-1'
                    labelClass='font-14'
                    ref={lengthInputRef}
                    type='number'
                    customStyle={`h-36 font-14 mb-2 ${lengthError ? 'red-border' : 'gray-border'}`}
                    label={`${t('_LENGTH')} (${metric ? t('_UNIT_CM') : t('_UNIT_INCH')})`}
                    name='length'
                    onFocus={() => {
                      setLengthError(false);
                    }}
                    onChange={(e) => {
                      setLength(e.target.value);
                    }}
                    value={length}
                  />
                  <CustomInput
                    containerClass='ps-1 pe-1'
                    labelClass='font-14'
                    type='number'
                    customStyle={`h-36 font-14 mb-2 ${widthError ? 'red-border' : 'gray-border'}`}
                    label={`${t('_WIDTH')} (${metric ? t('_UNIT_CM') : t('_UNIT_INCH')})`}
                    name='width'
                    onFocus={() => {
                      setWidthError(false);
                    }}
                    onChange={(e) => {
                      setWidth(e.target.value);
                    }}
                    value={width}
                  />
                  <CustomInput
                    containerClass='ps-1'
                    labelClass='font-14'
                    type='number'
                    customStyle={`h-36 font-14 mb-2 ${heightError ? 'red-border' : 'gray-border'}`}
                    label={`${t('_HEIGHT')} (${metric ? t('_UNIT_CM') : t('_UNIT_INCH')})`}
                    name='height'
                    onFocus={() => {
                      setHeightError(false);
                    }}
                    onChange={(e) => {
                      setHeight(e.target.value);
                    }}
                    value={height}
                  />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <CustomInput
                  containerClass='pe-1'
                  labelClass='font-14'
                  type='number'
                  customStyle={`h-36 font-14 mb-2 ${weightError ? 'red-border' : 'gray-border'}`}
                  label={`${t('_WEIGHT')} (${metric ? t('_UNIT_KG') : t('_UNIT_LB')})`}
                  name='weight'
                  onFocus={() => {
                    setWeightError(false);
                  }}
                  onChange={(e) => {
                    setWeight(e.target.value);
                  }}
                  value={weight}
                />

                <CustomInput
                  containerClass='ps-1'
                  labelClass='font-14'
                  type='number'
                  customStyle={`h-36 font-14 mb-2 ${costError ? 'red-border' : 'gray-border'}`}
                  label={`${t('_COST')} (${country === 'USA' ? 'USD' : country === 'China' ? 'CNY' : 'TL'})`}
                  name='cost'
                  onFocus={() => {
                    setCostError(false);
                  }}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                  value={cost}
                />
              </div>
            </div>
          </div>
          {hasResult && (
            <>
              <div
                className='shadow-sm'
                style={{
                  border: '1px solid #ddd',
                  borderRadius: 6,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <div className='d-flex justify-content-between mb-1 font-14'>
                  <div>{t('_WEIGHT')}</div>
                  <div>
                    {result?.realWeight} {t('_UNIT_KG')}
                  </div>
                </div>
                <div className='d-flex justify-content-between mb-1 font-14'>
                  <div>{t('_DIMENSIONAL_WEIGHT')}</div>
                  <div>
                    {result?.dimWeight} {t('_UNIT_KG')}
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2 font-14' style={{ color: '#000', fontFamily: 'HelveticaBoldCaps' }}>
                  <div>{t('_CHARGEABLE_WEIGHT_CALC')}</div>
                  <div>
                    {result?.chargeableWeight} {t('_UNIT_KG')}
                  </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between mb-1 font-14'>
                  <div>{t('_TRANSPORTATION_CALC')}</div>
                  <div>{result?.transportation} ₾</div>
                </div>
                <div className='d-flex justify-content-between mb-1 font-14'>
                  <div>{t('_INSURANCE_CALC')}</div>
                  <div>{result?.insurance} ₾</div>
                </div>
                <div className='d-flex justify-content-between mt-2 font-14' style={{ color: '#000', fontFamily: 'HelveticaBoldCaps' }}>
                  <div>{t('_TOTAL')}</div>
                  <div>{result?.total} ₾</div>
                </div>
              </div>
              <div className='mb-2'></div>
              <div
                className='font-14'
                dangerouslySetInnerHTML={{
                  __html: t(country === 'Turkey' ? '_CALCULATOR_ARTICLE_TURKEY' : country === 'China' ? '_CALCULATOR_ARTICLE_CHINA' : '_CALCULATOR_ARTICLE')
                }}
              ></div>
            </>
          )}
        </div>
        <div className='sidebarButtonDiv mt-2'>
          <Button isLoading={isLoading} handleClick={handleSubmit} btnStyle='bg-orange primary-btn-w-h' text={t('_CALCULATE')} color='color-white' />
        </div>
      </div>
    </div>
  );
}

export default PriceCalculatorNew;
