import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { setupStore } from './store/store';
// import './localization/i18n';
import { SiteLoader } from './components/SiteLoader';

const store = setupStore();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SiteLoader />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
