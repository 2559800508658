// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import apiRequest from '../../../components/ApiRequest';
import Flight from '../../../components/Trackings/Flight';
import Dashboard from '../Dashboard';
import GlobalContext from '../../../components/GlobalContext';
import NoPackage from '../../../components/NoPackage';
import Button from '../../../components/Button';
import CourierMap from '../../../components/Trackings/CourierMap';
import { customerSliceActions } from '../../../store/slices/customer.slice';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/modal/Modal';
import Code128Generator from 'code-128-encoder';

const Arrived = () => {
  const { setShowLoader, setAlert } = useContext(GlobalContext);
  const {
    data: getArrived,
    isLoading,
    isSuccess
  } = useQuery('getArrived', () =>
    apiRequest({
      command: 'get_arrived',
      sessionId: localStorage.getItem('token')
    })
  );
  const [mapOpen, setMapOpen] = useState(false);
  const [GPSError, setGPSError] = useState(false);
  const [currentCoord, setCurrentCoord] = useState(false);
  const dispatch = useDispatch();
  const reduxGoogleMap = useSelector(state => state.customer.settings.address);
  const encoder = new Code128Generator();
  const [showLockerBarcode, setShowLockerBarcode] = useState(false);
  const [lockerBarcode, setLockerBarcode] = useState(null);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);
  const { t } = useTranslation();

  const arrived = isSuccess && getArrived.data.result?.packages;
  const courierParcels = arrived && arrived?.filter(
    (parcel) =>
      (parcel?.notice_status === 'NONE' ||
        parcel?.notice_status === 'COMING' ||
        parcel?.notice_status === 'RETURNED') && parcel?.locker_id < 1
  );
  const map = useRef(null);

  useEffect(() => {
    fetch('https://usa2georgia.com/private_usa_ws/rest.php', {
      method: 'POST',
      body: JSON.stringify({
        command: 'get_google_map_address',
        secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
        sessionId: localStorage.getItem('token')
      })
    }).then(response => {
      response.json().then(res => {
        if (res?.status === 'OK' && res?.result.latitude !== '0') {
          dispatch(customerSliceActions.setAddressSetting({ lat: parseFloat(res?.result.latitude), lng: parseFloat(res?.result.longitude), comment: res?.result.addressComment }));
        } else {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((e) => {
              setCurrentCoord({ lat: e.coords.latitude, lng: e.coords.longitude });
              dispatch(customerSliceActions.setAddressSetting({ lat: e.coords.latitude, lng: e.coords.longitude, comment: '' }));
            }, () => {
              setGPSError(true);
            });
          }
        }
      });
    });
  }, []);

  return (
    <Dashboard>
      {arrived && arrived.length > 0 ? (
        <div className="pt-4">
          {courierParcels.length > 0 && (
            <Button
              handleClick={() => {
                // if (!GPSError) {
                setMapOpen(true);
                // } else {
                // setAlert(['error', t('_GPS_PERMISSION_REQUIRED')]);
                // }
              }}
              btnStyle="button-primary courierBtn"
              text={t('_key_currier_service')}
            />
          )}
          {mapOpen && (
            <CourierMap mapOpen={mapOpen} setMapOpen={setMapOpen} ref={map} reduxGoogleMap={reduxGoogleMap} />
          )}
          {arrived.map((item, index) => (
            <Flight
              packageStatus="arrived"
              key={index}
              packageDetail={item?.trackings}
              item={item}
              setShowLockerBarcode={setShowLockerBarcode}
              setLockerBarcode={setLockerBarcode}
            />
          ))}
        </div>
      ) : !isLoading ? (
        <>
          <NoPackage />
        </>
      ) : null}
      <Modal showModal={showLockerBarcode} setShowModal={setShowLockerBarcode}>
        {lockerBarcode && (
          <div className="barcode-container">
            <p className="barcode-title">{lockerBarcode}</p>
            <div className="parent-128">
              <div className="code128">{encoder.encode(lockerBarcode)}</div>
            </div>
            <div>
              <h4 className="text-center">
                LOCKER.GE <br />
                {t('_SMS_CODE')}
              </h4>
            </div>
            <Button
              handleClick={() => setShowLockerBarcode(false)}
              type="button"
              btnStyle="bg-orange fill-balance-btn"
              text={t('_CLOSE')}
              color="color-white"
            />
          </div>
        )}
      </Modal>
    </Dashboard>
  );
};

export default Arrived;
