/* eslint-disable space-before-function-paren */
// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';

// components
import Input from '../../../components/input/Input';
import Button from '../../../components/Button';
import apiRequest from '../../../components/ApiRequest';
import { EditProfileSchema } from '../../../components/validations/EditProfileSchema';
import GlobalContext from '../../../components/GlobalContext';
import ChangablePackageDetails from '../../../components/ChangablePackageDetails';
import { UsernameIcon } from '../../../assets/icons';
import Modal from '../../../components/modal/Modal';
import { useNavigate } from 'react-router';

const ProfileSettings = ({ userInfo, setOpenSettings }) => {
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [openTerminateCustomer, setOpenTerminateCustomer] = useState(false);
  const [removeUserLastStep, setRemoveUserLastStep] = useState(false);
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { token, setAlert, setToken } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { values, handleChange, errors, touched, handleBlur, isValid } =
    useFormik({
      initialValues: {
        email: userInfo.email || '',
        mobile: userInfo.mobile || '',
        non_georgian_citizen: userInfo.non_georgian_citizen || ''
      },
      enableReinitialize: true,
      validationSchema: EditProfileSchema
    });

  const changeProfileSettings = useMutation(
    () =>
      apiRequest(
        {
          command: 'save_customer_settings',
          sessionId: token
        },
        {
          data: {
            profile: values
          }
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSettings(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await changeProfileSettings.mutateAsync();
      queryClient.invalidateQueries('getCustomerSettings');
    } catch (error) {
      console.log('error', error);
    }
  };
  const { setShowLoader } = useContext(GlobalContext);

  const removeAvatar = useMutation(
    () =>
      apiRequest({
        command: 'remove_avatar',
        sessionId: token
      }),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          localStorage.removeItem('avatar');
          localStorage.removeItem('extention');
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );
  const handleRemoveAvatar = async () => {
    try {
      await removeAvatar.mutateAsync();
      queryClient.invalidateQueries('getAvatar');
      queryClient.invalidateQueries('getCustomerSettings');
      queryClient.invalidateQueries('userInfo');
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    setShowLoader(removeAvatar.isLoading);
  }, [removeAvatar.isLoading]);

  const getAvatar = useMutation(
    () =>
      apiRequest({
        command: 'get_avatar',
        sessionId: token
      }),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          localStorage.setItem('avatar', response.data.result.file);
          localStorage.setItem('extention', response.data.result.extention);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  const uploadAvatar = useMutation(
    (props) =>
      apiRequest(
        {
          command: 'upload_avatar',
          sessionId: token,
          method: 'POST'
        },
        {
          extention: props.extention,
          base64data: props.base64
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          getAvatar.mutate();
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );
  const handleUploadAvatar = async (baseURL, extention) => {
    try {
      await uploadAvatar.mutateAsync({
        base64: baseURL.split(',')[1],
        extention: extention
      });
      queryClient.invalidateQueries('getAvatar');
      queryClient.invalidateQueries('getCustomerSettings');
      queryClient.invalidateQueries('userInfo');
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      baseURL = reader.result;
      const extention = baseURL.split('/')[1].split(';')[0];
      handleUploadAvatar(baseURL, extention);
    };
  };
  useEffect(() => {
    setShowLoader(uploadAvatar.isLoading);
  }, [uploadAvatar.isLoading]);

  const checkPassword = useMutation(
    () =>
      apiRequest(
        {
          command: 'password_check',
          sessionId: token
        },
        {
          password: password
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setRemoveUserLastStep(true);
          setOpenTerminateCustomer(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  const removeUser = useMutation(
    () =>
      apiRequest(
        {
          command: 'terminate_customer',
          sessionId: token
        },
        {
          password: password
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          logout.mutate();
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  const handleRemoveUser = async () => {
    try {
      await removeUser.mutateAsync();
      queryClient.invalidateQueries('getCustomerSettings');
      queryClient.invalidateQueries('userInfo');
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    setShowLoader(removeUser.isLoading);
  }, [removeUser.isLoading]);

  const logout = useMutation(
    () =>
      apiRequest({
        command: 'logout',
        sessionId: token
      }),
    {
      onSuccess: () => {
        localStorage.removeItem('token');
        queryClient.clear();
        setToken(null);
        navigate('/');

        document
          .querySelectorAll('.kommunicate-script')
          .forEach((item) => item.remove());
        const kommunicateIframe = document.getElementById(
          'kommunicate-widget-iframe'
        );
        if (kommunicateIframe) {
          kommunicateIframe.remove();
        }
      },
      onError: (response) => {
        console.log(response);
      }
    }
  );

  return (
    <div className="d-flex flex-column height-100 justify-content-between">
      <h1 className="font-size-16 mb-5 bold sidebarTitle">
        {t('_VIEW_PROFILE')}
      </h1>
      <form
        className="d-flex flex-column height-100 justify-content-between form"
        onSubmit={handleSubmit}
      >
        <div className="sidebar-child-scroll">
          <div className="pr-60">
            <div>
              {userInfo?.avatar ? (
                <ChangablePackageDetails
                  containerStyle="userAvatar"
                  src={`data:image/${userInfo?.avatar?.extention};base64,${userInfo?.avatar?.file}`}
                  title={t('_CHANGE_PHOTO')}
                  uploadable
                  editable
                  onChange={(e) => handleFileInputChange(e)}
                  // editableFunction={handleFileInputChange}
                  deletable
                  deleteFunction={() => setOpenModalAvatar(true)}
                />
              ) : (
                <ChangablePackageDetails
                  containerStyle="userAvatar"
                  icon={<UsernameIcon />}
                  title={t('_CHANGE_PHOTO')}
                  uploadable
                  editable
                  onChange={(e) => handleFileInputChange(e)}
                  // editableFunction={handleFileInputChange}
                />
              )}
              {userInfo && userInfo.non_georgian_citizen !== 1 && (
                <Input
                  label={t('_SITE_FIRST_NAME_GEO')}
                  disabled={true}
                  value={userInfo.first_name_ge}
                />
              )}
              <Input
                label={t('_SITE_FIRST_NAME_LAT')}
                disabled={true}
                value={userInfo.first_name}
              />
              {userInfo && userInfo.non_georgian_citizen !== 1 && (
                <Input
                  label={t('_SITE_LAST_NAME_GEO')}
                  disabled
                  value={userInfo.last_name_ge}
                />
              )}
              <Input
                label={t('_SITE_LAST_NAME_LAT')}
                disabled={true}
                value={userInfo.last_name}
              />
            </div>
            <div>
              <Input
                label={t('_EMAIL')}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email ? t(errors.email) : ''}
              />
              <Input
                label={t('_LABEL_MOBILE')}
                name="mobile"
                value={values.mobile}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.mobile && errors.mobile ? t(errors.mobile) : ''}
              />
              <Input
                label={t('_IDENTNO')}
                disabled={true}
                value={userInfo.identification}
              />
              <Button
                text={t('_key_delete_user')}
                type="button"
                btnStyle="secondary-btn-w-h mw-100"
                handleClick={() => setOpenTerminateCustomer(true)}
              />
            </div>
          </div>
        </div>
        <div className="sidebarButtonDiv">
          <Button
            disabled={!isValid}
            isLoading={changeProfileSettings.isLoading}
            handleClick={handleSubmit}
            type="submit"
            btnStyle="primary-btn-w-h bg-orange"
            text={t('_SAVE')}
            color="color-white"
          />
        </div>
      </form>
      <Modal
        showModal={openTerminateCustomer}
        setShowModal={setOpenTerminateCustomer}
      >
        <div className="d-flex flex-column">
          <p className="modalText">{t('_FILL_PASSWORD')}</p>
          <Input
            name="password"
            placeholder={t('_PASSWORD')}
            type="password"
            value={password}
            customStyle="mb-0 mt-4 pt-0"
            onChange={(e) => setPassword(e.target.value)}
            onBlur={handleBlur}
            error={touched.mobile && errors.mobile ? t(errors.mobile) : ''}
          />
          <div className="d-flex align-items-center justify-content-center modal-btn-div mt-4">
            <Button
              handleClick={() => {
                setOpenTerminateCustomer(false);
              }}
              type="button"
              btnStyle="bg-orange me-4 fill-balance-btn secondary-btn-bg"
              text={t('_CANCEL')}
              color="color-white"
            />
            <Button
              handleClick={() => {
                checkPassword.mutate();
              }}
              isLoading={checkPassword.isLoading}
              type="button"
              btnStyle="bg-orange  fill-balance-btn"
              text={t('_key_continue')}
              color="color-white"
              disabled={checkPassword.isLoading}
            />
          </div>
        </div>
      </Modal>
      <Modal
        showModal={removeUserLastStep}
        setShowModal={setRemoveUserLastStep}
      >
        <div className="d-flex flex-column">
          <p className="modalText">{t('_DELETE_USER_CONFIRMATION')}</p>
          <div className="d-flex align-items-center justify-content-center modal-btn-div mt-4">
            <Button
              handleClick={() => {
                setRemoveUserLastStep(false);
              }}
              type="button"
              btnStyle="bg-orange me-4 fill-balance-btn secondary-btn-bg"
              text={t('_CANCEL')}
              color="color-white"
            />
            <Button
              handleClick={handleRemoveUser}
              isLoading={removeUser.isLoading}
              type="button"
              btnStyle="bg-orange  fill-balance-btn "
              text={t('_REMOVE')}
              color="color-white"
              disabled={removeUser.isLoading}
            />
          </div>
        </div>
      </Modal>

      <Modal showModal={openModalAvatar} setShowModal={setOpenModalAvatar}>
        <div className="d-flex flex-column">
          <p className="modalText">{t('_REMOVE_AVATAR_CONFIRM')}</p>
          <div className="d-flex align-items-center justify-content-center modal-btn-div mt-4">
            <Button
              handleClick={() => {
                handleRemoveAvatar();
                setOpenModalAvatar(false);
              }}
              type="button"
              btnStyle="bg-orange me-4 fill-balance-btn"
              text={t('_YES')}
              color="color-white"
            />
            <Button
              handleClick={() => {
                setOpenModalAvatar(false);
              }}
              type="button"
              btnStyle="bg-orange fill-balance-btn secondary-btn-bg"
              text={t('_NO')}
              color="color-white"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileSettings;
