import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ListButtonsNew.css';
import UsaFlagIcon from '../../assets/images/flags/DELAWARE.svg';
import TurkeyFlagIcon from '../../assets/images/flags/TURKEY.svg';
import ChinaFlagIcon from '../../assets/images/flags/CHINA.svg';

const ListButtonsNew = ({ countryList, activeTab, setActiveTab }) => {
  const { t } = useTranslation();
  return (
    <div className='address-tab-items'>
      {countryList.map((item, index) => (
        <div key={index} onClick={() => setActiveTab(item)}>
          <div>
            <div>
              <div className={`address-tab-item ${activeTab === item && 'address-tab-item-active'}`}>
                <div className='address-tab-item-flag' style={{ textAlign: 'center' }}>
                  <img style={{ width: 40 }} src={item === 'DELAWARE' ? UsaFlagIcon : item === 'TURKEY' ? TurkeyFlagIcon : ChinaFlagIcon} alt={t(`_${item}`)} />
                  <p style={{ fontSize: 12 }}>{t(`_${item}`)}</p>
                </div>
                <p className='address-tab-item-title'>{t(`_${item}`)}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListButtonsNew;
