// hooks
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';

// components
import HowUsa2Works from './HowUsa2Works';
import WhyUsa2 from './WhyUsa2';
import Prices from './Prices';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BranchesSlider from './BranchesSlider';
import WhatWeOffer from './WhatWeOffer';
import OrangeLine from '../../components/OrangeLine';
import Contact from '../../components/Contact';
import Loader from '../../components/loader/Loader';

// assets
import MainImg from '../../assets/images/locker_image.jpeg';
import Wrapper from '../../components/Wrapper';
import GlobalContext from '../../components/GlobalContext';

export default function AuthPage() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const { showLoader, setShowLoader } = useContext(GlobalContext);

  useEffect(() => {
    if (['ka', 'en'].includes(lang)) {
      i18n.changeLanguage(lang);
    } 
    if (showLoader) {
      setShowLoader(false);
    }
  }, [showLoader]);

  return (
    <Wrapper>
      <div>
        <Loader />
        <div className='about-us-container'>
          <Row>
            <Col lg={6}>
              <OrangeLine />
              <h2 className='about-us-title mt-2'> {t('_WHO_WE_ARE')}</h2>
              <p className='about-us-left-p font-size-14' dangerouslySetInnerHTML={{ __html: t('_WHOWEAREC') }} />
            </Col>
            <Col lg={6}>
              <div className='about-us-right-div font-size-14'>
                <p className='about-us-right-title font-size-16'>USA2GEORGIA {t('_WHOWEAREA')}</p>
                <p className='about-us-right-p'>{t('_WHOWEAREB')}</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className='main-img-div'>
          <img className='main-img' width='100%' height='auto' src={MainImg} alt='main-img' />
        </div>
        <WhyUsa2 />
        <HowUsa2Works />
        <WhatWeOffer />
        <Prices />
        <Contact title={t('_CONTACT')} />
        <BranchesSlider />
      </div>
    </Wrapper>
  );
}
