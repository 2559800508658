// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';

// components
import GlobalContext from '../../components/GlobalContext';
import Button from '../../components/Button';
import apiRequest from '../../components/ApiRequest';

const Agreement = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showLoader, setShowLoader } =
    useContext(GlobalContext);
  const [showButton, setShowButton] = useState(false);
  const [noCache, setNoCache] = useState(0);
  const token = localStorage.getItem('agreementToken');

  useEffect(() => {
    setShowLoader(false);
  }, [showLoader]);

  useEffect(() => {
    if (['ka', 'en'].includes(lang)) {
      i18n.changeLanguage(lang);
    } 
    // console.log('Agreement.js - get_customer_info');
  }, []);

  const { data } = useQuery(
    'userInfo',
    () =>
      apiRequest({
        command: 'get_customer_info',
        sessionId: token
      }),
    {
      onSuccess: (response) => {
        if (response.data.message === '_AGREEMENT_NOT_APPROVED_FROM_APP' || response.data.result?.profile.agree === '0') {
          setShowButton(true);
        }
      }
    }
  );

  const doAgree = useMutation(
    () =>
      apiRequest({
        command: 'do_agree',
        sessionId: token
      }),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          navigate('/');
          setShowButton(false);
        }
      }
    }
  );

  useEffect(() => {
    setNoCache(Date.now());
  }, [noCache]);

  return (
    <div className="agreement-container">
      <iframe
        src={`${localStorage.getItem('i18nextLng') === 'en' 
        ? `https://usa2georgia.com/agreements/en.html?noCache=${noCache}` 
        : `https://usa2georgia.com/agreements/ka.html?noCache=${noCache}`
          }`}
      />
      {showButton && token && (
        <div className="d-flex agreement-button-div align-items-center justify-content-center">
          <Button
            handleClick={doAgree.mutate}
            text={t('_AGREE')}
            type="button"
            btnStyle="primary-btn-w-h bg-orange"
            color="color-white"
          />
        </div>
      )}
    </div>
  );
};

export default Agreement;
