import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { CrossIcon, SearchIcon } from '../../assets/icons';
import { useQueryClient } from 'react-query';
import Button from '../Button';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../GlobalContext';
import Modal from '../modal/Modal';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';

function CourierMap({ mapOpen, setMapOpen, reduxGoogleMap }, ref) {
  const ApiKey = 'AIzaSyDGsGC-xZ2_axxvsOKUiVz57BIshLOHRW8'; // 'AIzaSyDmpxrOS1_g_rAoojFWS-K6Y7zhzU2IeBY'; // 'AIzaSyDGsGC-xZ2_axxvsOKUiVz57BIshLOHRW8'; // 'AIzaSyCOdn-bz1DxxWUQWfN7vt2WX3bPZH4yCOw';
  const GeoCodeApiKey = 'AIzaSyCt8qknMvkxyHVYcIdla7qZWrl3aiqfNAQ';
  const geocodeJSON = 'https://maps.googleapis.com/maps/api/geocode/json';

  const { t } = useTranslation();
  const { token, setAlert, setShowLoader } = useContext(GlobalContext);
  const queryClient = useQueryClient();

  const [address, setAddress] = useState({
    lat: reduxGoogleMap.lat,
    lng: reduxGoogleMap.lng,
    additionalInfo: reduxGoogleMap.comment,
    city: '',
    country: '',
    placeId: '',
    shortAddress: '',
    longAddress: '',
    formattedAddress: ''
  });

  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: reduxGoogleMap.lat,
    lng: reduxGoogleMap.lng
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [autocompleteText, setAutocompleteText] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [retrieveMessage, setRetrieveMessage] = useState('');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places'],
    googleMapsApiKey: ApiKey
  });

  // Google Map
  const GoogleMapOnLoad = React.useCallback(function callback(map) {
    reverseGeoCoder(map?.center?.lat(), map?.center?.lng());
    setMap(map);
  }, []);

  const GoogleMapOnUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const GoogleMapOnClick = (event) => {
    reverseGeoCoder(event?.latLng?.lat(), event?.latLng?.lng());
  };


  // Marker 
  const MarkerOnLoad = () => {
  };

  const MarkerOnDragEnd = (marker) => {
    reverseGeoCoder(marker?.latLng?.lat(), marker?.latLng?.lng());
  };


  // Autocompleate

  const AutocompleteOnLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const AutocompleteOnPlaceChanged = () => {
    const place = autocomplete.getPlace();
    setMapCenter({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng()
    }
    );
    reverseGeoCoder(place?.geometry?.location?.lat(), place?.geometry?.location?.lng());
  };

  function reverseGeoCoder(lat, lng, center = false) {
    if (lat === undefined) {
      return;
    }
    const url = `${geocodeJSON}?latlng=${lat},${lng}&key=${GeoCodeApiKey}`;
    axios.get(url).then(({ data }) => {
      setAddress({
        ...address,
        lat,
        lng,
        formattedAddress: data?.results[0]?.formatted_address,
        placeId: data?.results[0]?.place_id,
        shortAddress: data?.results[0]?.address_components[0]?.short_name,
        longAddress: data?.results[0]?.address_components[0]?.long_name
      });
      setAutocompleteText(data?.results[0]?.formatted_address);
      if (center) {
        setMapCenter({ lat, lng });
      }
    }).catch((error) => console.log(error));
  }

  const handleOrderCourier = () => {
    if (address.lat === 41.72275936989263 && address.lng === 44.721973997190965) {
      setAlert(['error', t('_COURIER_RETRIVE_TO_VAJA_BRANCH_NOT_AVAILABLE')]);
      return;
    }
    setShowLoader(true);
    fetch('https://usa2georgia.com/private_usa_ws/rest.php', {
      method: 'POST',
      body: JSON.stringify({
        command: 'save_google_map_address',
        secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
        sessionId: token,
        params: JSON.stringify({
          latitude: address.lat,
          longitude: address.lng,
          formattedAddress: address.formattedAddress,
          addressComment: address.additionalInfo,
          placeId: address.placeId,
          shortAddress: address.shortAddress,
          longAddress: address.longAddress
        })
      })
    }).then(response => {
      response.json().then(res => {
        if (res?.status === 'OK') {
          // call retrive courier CHECK

          fetch('https://usa2georgia.com/private_usa_ws/rest.php', {
            method: 'POST',
            body: JSON.stringify({
              command: 'retrieve_courier',
              secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
              sessionId: token,
              action: 'CHECK'
            })
          }).then(response => {
            response.json().then(res => {
              if (res?.status === 'OK') {
                // call retrive courier 
                setRetrieveMessage(res?.result?.message);
                setOpenConfirmation(true);
              } else {
                // setAlert(['error', t(res?.message)]);
                setAlert(['error', res?.message.startsWith('_') ? t(res?.message) : res?.message]);
              }
            });
          });
        } else {
          setAlert(['error', res?.message.startsWith('_') ? t(res?.message) : res?.message]);
          // setAlert(['error', t(res?.message)]);
        }
        setShowLoader(false);
      });
    });
  };

  const retriveCourierAction = () => {
    fetch('https://usa2georgia.com/private_usa_ws/rest.php', {
      method: 'POST',
      body: JSON.stringify({
        command: 'retrieve_courier',
        secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
        sessionId: token,
        action: 'RETRIEVE'
      })
    }).then(response => {
      response.json().then(res => {
        if (res?.status === 'OK') {
          // call retrive courier 
          setOpenConfirmation(false);
          setMapOpen(false);
          queryClient.invalidateQueries('getArrived');
          setAlert(['success', res?.message.startsWith('_') ? t(res?.message) : res?.message]);
          // setAlert(['success', t(res?.message)]);
        } else {
          setAlert(['error', res?.message.startsWith('_') ? t(res?.message) : res?.message]);
          // setAlert(['error', t(res?.message)]);
        }
      });
    });
  };

  const onMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          reverseGeoCoder(position?.coords?.latitude, position?.coords?.longitude, true);
        }, () => {
          setAlert(['error', t('_GPS_PERMISSION_REQUIRED')]);
        }
      );
    } else {
      setAlert(['error', t('_GPS_PERMISSION_REQUIRED')]);
    }
  };

  return (
    <div
      className="MapPopup d-flex align-items-center justify-content-center"
      onClick={() => setMapOpen(false)}
      ref={map}
    >
      <div className="mapDiv" onClick={(e) => e.stopPropagation()}>
        <div className="myMap" style={{ height: '100%' }}>
          <div className="CloseIcon" onClick={() => setMapOpen(false)}>
            <CrossIcon className="closeMap" />
          </div>
          <div style={{ position: 'absolute', right: 0, bottom: 120, zIndex: 10, cursor: 'pointer' }} onClick={onMyLocation}>
            <div className="custom-map-control-button"></div>
          </div>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%'
              }}
              center={mapCenter}
              zoom={17}
              onLoad={GoogleMapOnLoad}
              onUnmount={GoogleMapOnUnmount}
              onClick={GoogleMapOnClick}
              options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                clickableIcons: false
              }}
            >
              <Autocomplete
                onLoad={AutocompleteOnLoad}
                onPlaceChanged={AutocompleteOnPlaceChanged}
              >
                <div>
                  <div className="inputDiv searchDiv">
                    <input
                      type="text"
                      placeholder={t('_SELECT_ADDRESS_TITLE')}
                      value={autocompleteText}
                      onChange={(e) => {
                        setAutocompleteText(e.target.value);
                      }}
                    />
                  </div>
                  <div className="additional-info">
                    <label htmlFor="defaultAddress" className="addressLabel">
                      {t('_ADDITIONAL_DETAIL_TITLE')}
                      <div className="inputDivAdditional ">
                        <input
                          type="text"
                          value={address.additionalInfo}
                          onChange={(e) => {
                            setAddress({
                              ...address,
                              additionalInfo: e.target.value
                            });
                          }}
                          placeholder="კვარტალი, კორპუსი, სადარბაზო"
                        />
                      </div>
                    </label>
                    <div className="buttonDivAdditional d-flex">
                      <Button
                        text={t('_ORDER_COURIER')}
                        btnStyle="button-primary"
                        handleClick={handleOrderCourier}
                      />
                    </div>
                  </div>
                </div>
              </Autocomplete>
              <Marker
                onDragEnd={MarkerOnDragEnd}
                onLoad={MarkerOnLoad}
                animation={window.google.maps.Animation.DROP}
                position={address}
                draggable={true}
              />
            </GoogleMap>
          ) : <></>}
        </div>
      </div>
      <Modal showModal={openConfirmation} setShowModal={setOpenConfirmation}>
        <div className="d-flex flex-column">
          <p className="modalText" style={{ height: '390px', overflow: 'auto' }}>
            {retrieveMessage?.includes('<') &&
              retrieveMessage?.includes('>') ? (
              <div dangerouslySetInnerHTML={{ __html: retrieveMessage }} />) : (t(retrieveMessage))}
          </p>
          <div className="d-flex align-items-center justify-content-center modal-btn-div mt-4">
            <Button
              handleClick={retriveCourierAction}
              type="button"
              btnStyle="bg-orange me-4 fill-balance-btn"
              text={t('_YES')}
              color="color-white"
            />
            <Button
              handleClick={() => {
                setOpenConfirmation(false);
              }}
              type="button"
              btnStyle="bg-orange fill-balance-btn secondary-btn-bg"
              text={t('_NO')}
              color="color-white"
            />
          </div>
        </div>
      </Modal>

    </div >
  );
}

export default forwardRef(CourierMap);
