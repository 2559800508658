// hooks
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import { Link } from 'react-router-dom';
import Button from '../Button';
import GlobalContext from '../GlobalContext';
import apiRequest from '../ApiRequest';

// assets
import SmallIcon from '../SmallIcon';
import { LogoutIcon, MailIcon, NotificationIcon } from '../../assets/icons';
import { useNavigate } from 'react-router';

const LoginedUserNavbar = () => {
  const { t } = useTranslation();
  const { token, setToken } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const logout = useMutation(
    () =>
      apiRequest({
        command: 'logout',
        sessionId: token
      }),
    {
      onSuccess: () => {
        localStorage.removeItem('token');
        queryClient.clear();
        setToken(null);
        navigate('/');

        document.querySelectorAll('.kommunicate-script').forEach(item => item.remove());
        const kommunicateIframe = document.getElementById('kommunicate-widget-iframe');
        if (kommunicateIframe) {
          kommunicateIframe.remove();
        }
      },
      onError: (response) => {
        console.log(response);
      }
    }
  );

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="me-3 mt-1">
          <Link to="/notifications">
            <SmallIcon
              imgStyle="gray-icon"
              bg="icon-light-bg"
              alt="notification"
              img={<NotificationIcon />}
            />
          </Link>
        </div>
        {/* <div className="me-3 mt-1">
          <Link to="/notifications">
            <SmallIcon
              imgStyle="gray-icon"
              bg="icon-light-bg"
              alt="notification"
              img={<MailIcon />}
            />
          </Link>
        </div> */}
        <Button
          icon={<LogoutIcon />}
          handleClick={logout.mutate}
          btnStyle="bg-orange logout-btn"
          text={t('_LOGOUT')}
          color="color-white"
        />
      </div>
    </div>
  );
};

export default LoginedUserNavbar;
