import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBotIcon, ExternalLinkIcon, UsernameIcon } from '../../../assets/icons';
import { chatbotSliceActions } from '../../../store/slices/chatbot.slice';
import { sendMessageAsyncThunk } from '../../../store/thunks/chatbot.thunk';
import { getGeorgianDateTime } from '../../../utils/utils';
import { BranchInfo } from '../BranchInfo/BranchInfo';
import { FileMessage } from '../FileMessage';
import './ChatMessage.css';
import { AudioMessage } from '../AudioMessage';
import Typed from 'react-typed';
import { t } from 'i18next';

export const ChatMessage = ({ message, custom, data, component, datetime, userMessage, buttons = [], scrollDown }) => {
  const dispatch = useDispatch();
  const firstName = useSelector((state) => state.customer.firstName);
  const fullName = useSelector((state) => state.customer.fullName);
  const avatar = useSelector((state) => state.customer.avatar);
  const [scrollFlag, setScrollFlag] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (scrollFlag) {
        scrollDown();
      }
    }, 200);
    if (!scrollFlag) {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [scrollFlag]);

  const sendMessageToServer = (title, clickArgs) => {
    dispatch(sendMessageAsyncThunk({ ...clickArgs, message: title, sessionId: localStorage.getItem('token'), firstName: firstName }));
    dispatch(
      chatbotSliceActions.addMessage({
        userMessage: true,
        message: title,
        datetime: getGeorgianDateTime()
      })
    );
  };

  return (
    <div className='u2g-message' style={{ flexDirection: userMessage ? 'row-reverse' : 'row' }}>
      <div>
        <div
          style={{
            backgroundColor: userMessage ? '#777' : '#f68220',
            width: 36,
            height: 36,
            borderRadius: '100%',
            padding: avatar === null ? 5 : 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: -8
          }}
        >
          {userMessage ? (
            avatar === null ? (
              <UsernameIcon fill='#fff' width={28} />
            ) : (
              <img
                style={{ display: 'block', width: '100%', height: '100%', objectFit: 'cover', borderRadius: '100%' }}
                src={`data:image/${avatar?.extention};base64, ${avatar?.file}`}
              />
            )
          ) : (
            <ChatBotIcon fill='#fff' width={26} />
          )}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, fontSize: '12px' }}>
        <div style={{ color: '#888', textAlign: userMessage ? 'right' : 'left' }}>{userMessage ? fullName : t('_CHATBOT_NAME')}</div>
        {
          custom ? (
            <div className='u2g-message-message-body' style={{ maxWidth: 300 }}>
              {custom === 'branch-list' && <BranchInfo data={data} />}
              {custom === 'file' && <FileMessage data={data} />}
              {custom === 'audio' && <AudioMessage data={data} />}
            </div>
          ) 
          : (
            <div
              className='u2g-message-message-body'
              style={{ backgroundColor: '#eee', padding: 10, borderRadius: 8, fontSize: 14, color: '#444', maxWidth: 300 }}
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
          )
          // : (
          //   <Typed
          //     className='u2g-message-message-body'
          //     style={{ backgroundColor: '#eee', padding: 10, borderRadius: 8, fontSize: 14, color: '#444', maxWidth: 300 }}
          //     strings={[message]}
          //     typeSpeed={10}
          //     preStringTyped={() => {
          //       setScrollFlag(true);
          //     }}
          //     showCursor={false}
          //     onComplete={() => {
          //       scrollDown();
          //       setScrollFlag(false);
          //     }}
          //   />)
        }
        {buttons.length > 0 && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, width: 300 }}>
            {buttons.map((element, index) => {
              return element.externalLink ? (
                <a href={element.externalLink} target='_blank' className='u2g-message-action-button' key={index} rel='noreferrer'>
                  <div>{element.title}</div>
                  {element.externalLink && (
                    <div style={{ marginLeft: 10 }}>
                      <ExternalLinkIcon className='u2g-message-action-button-external-link' />
                    </div>
                  )}
                </a>
              ) : (
                <div className='u2g-message-action-button' key={index} onClick={() => sendMessageToServer(element.title, element.clickArgs)}>
                  <div>{element.title}</div>
                  {element.externalLink && (
                    <div style={{ marginLeft: 10 }}>
                      <ExternalLinkIcon className='u2g-message-action-button-external-link' />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <div style={{ color: '#aeaeae', textAlign: userMessage ? 'right' : 'left' }}>{datetime}</div>
      </div>
    </div>
  );
};
