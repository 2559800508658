import React from 'react';
import usa2georgiaLogoBlack from '../assets/images/logo-black.svg';

export const SiteLoader = () => {
  return (
    <div style={{ position: 'fixed', top: -50, left: 0, width: '100%', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img style={{ width: '250px' }} className='w-96' src={usa2georgiaLogoBlack} alt='logo' />
    </div>
  );
};
