import React, { useState, useRef, useEffect, useContext } from 'react';
import '../../scss/Declaration.scss';
import apiRequest from '../ApiRequest';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import NonChangablePackageDetail from '../NonChangablePackageDetail';
import { TrackingIcon, GlobeIcon, InboxIcon } from '../../assets/icons/index';
import Toggle from '../toggle/Toggle';
import ChangablePackageDetails from '../ChangablePackageDetails';
import Button from '../Button';
import SideBar from '../sidebar/SideBar';
import Shippers from '../Shippers';
import AddProduct from './AddProduct';
import AddNew from './AddNew';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../GlobalContext';
import useImage from '../hooks/useImage';
import Modal from '../modal/Modal';
import { getAwaitingAsyncThunk } from '../../store/thunks/packages.thunk';
import { useDispatch } from 'react-redux';
import { getAmountWithCurrency } from '../../utils/utils';

export default function Declaration({ parcel, handleClose, userData, packageCountry, isAwaiting }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const shipperRef = useRef([]);
  const productsRef = useRef([]);

  const shipperHashKey = 'shipperHash_' + packageCountry;
  const shipperListKey = 'shipperList_' + packageCountry;
  const { setAlert, setShowLoader } = useContext(GlobalContext);

  const [openShippers, setOpenShippers] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [declarationDetails, setDeclarationDetails] = useState({});
  const [shipper, setShipper] = useState({});
  const [privateShipperName, setPrivateShipperName] = useState('');
  const [goods, setGoods] = useState([]);
  const [search, setSearch] = useState('');
  const [priceValue, setPriceValue] = useState();
  const [quantityValue, setQuantityValue] = useState(1);
  const [privateParcel, setPrivateParcel] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [shippersList, setShippersList] = useState(JSON.parse(localStorage.getItem(shipperListKey)));
  const [shippersLoading, setShippersLoading] = useState(true);

  const shipperName = shipper?.name;
  const { loading, image, error } = useImage(shipperName);

  const [selectedCountryData, setSelectedCountryData] = useState({
    icon: 'DELAWARE.svg',
    label: '$',
    name: 'DELAWARE',
    value: 'us'
  });

  const [noDropDown, setNoDropDown] = useState(false);
  const [shipperHash, setShipperHash] = useState(localStorage.getItem(shipperHashKey));

  const { data: getDeclaration } = useQuery(['getDeclaration', parcel?.package_id], () =>
    apiRequest(
      {
        command: 'get_declaration',
        sessionId: localStorage.getItem('token')
      },
      {
        packageId: parcel && parseInt(parcel?.package_id)
      }
    )
  );

  useEffect(() => {
    if (packageCountry !== 'UNKNOWN') {
      const countries = JSON?.parse(localStorage.getItem('countries'));
      const currencies = JSON?.parse(localStorage.getItem('currencies'));
      setSelectedCountryData({ name: packageCountry, label: currencies[packageCountry], value: countries[packageCountry], icon: `${packageCountry}.svg` });
    }
    const noDD = Object.keys(JSON?.parse(localStorage.getItem('countries')))?.length === 1;
    setNoDropDown(noDD);
  }, [packageCountry]);

  useEffect(() => {
    if (privateParcel) {
      setShipper(-2);
    } else {
      setShipper({});
    }
  }, [privateParcel]);

  useEffect(() => {
    setDeclarationDetails(getDeclaration?.data?.result);
  }, [getDeclaration]);

  useEffect(() => {
    setPrivateParcel(declarationDetails?.shipper_id === -2);
    declarationDetails?.shipper_id === -2 && setPrivateShipperName(declarationDetails?.shipper);
  }, [declarationDetails]);

  useEffect(() => {
    setShipper(
      declarationDetails && {
        id: parseInt(declarationDetails.shipper_id),
        name: declarationDetails.shipper
      }
    );
  }, [declarationDetails]);

  useEffect(() => {
    if (getDeclaration?.data?.result?.goods) {
      setGoods(getDeclaration?.data?.result?.goods);
    }
  }, [getDeclaration]);

  useEffect(() => {
    !openProducts && setPriceValue();
  }, [openProducts]);

  let productPriceTotal = 0;
  for (let i = 0; i < goods.length; i++) {
    productPriceTotal += goods[i]?.unit_price * goods[i]?.unit_count;
  }

  //* * Shipper-ის არჩევა */

  //   const { data: getShippers, isLoading: shippersLoading } = useQuery(
  //     'getShippers',
  //     () =>
  //       apiRequest(
  //         {
  //           command: 'get_shippers',
  //           sessionId: localStorage.getItem('token')
  //         },
  //         {
  //           checksum: '9B291DF109E918E95F694D921336EF91'
  //         }
  //       )
  //   );

  useEffect(() => {
    fetch('https://usa2georgia.com/private_usa_ws/rest.php', {
      method: 'POST',
      body: JSON.stringify({
        command: 'get_shippers',
        secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
        sessionId: localStorage.getItem('token'),
        checksum: shipperHash,
        country: packageCountry
      })
    }).then((response) => {
      response.json().then((res) => {
        if (res?.status === 'OK' && res?.result?.shippers.length > 0) {
          // console.log(res);
          localStorage.setItem(shipperHashKey, res?.result?.checksum);
          localStorage.setItem(shipperListKey, JSON.stringify(res?.result?.shippers));
          setShippersList(res?.result?.shippers);
          setShipperHash(res?.result?.checksum);
        } else {
          setShippersList(JSON.parse(localStorage.getItem(shipperListKey)));
          setShipperHash(localStorage.getItem(shipperHashKey));
        }
        setShippersLoading(false);
      });
    });
  }, []);

  const { data: getPackageDescriptions, isLoading: packageDetailsLoading } = useQuery('getPackageDescriptions', () =>
    apiRequest({
      command: 'get_package_descriptions',
      sessionId: localStorage.getItem('token')
    })
  );
  const getProductCategories = getPackageDescriptions?.data?.result;

  useEffect(() => {
    setShowLoader(packageDetailsLoading);
  }, [packageDetailsLoading]);

  function setShipperActive(e, shipper) {
    setShipper(shipper);
    for (let i = 0; i < shippersList.length; i++) {
      const eachShipper = shippersList[i];
      if (eachShipper.id === shipper.id) {
        e.target.classList.toggle('active');
      }
    }
    setOpenShippers(false);
    setSearch('');
  }

  function setShipperActiveBox(activatedShipper) {
    setShipper(activatedShipper);
    setOpenShippers(false);
  }

  //* * პროდუქტის დამატება */
  function changeProducts(option, price, quantity) {
    const newProduct = {
      code: option.code,
      description: option.short_desc_ge || option.desc_ge,
      unit_price: price,
      unit_count: quantity ?? 1
    };
    setGoods([...goods, newProduct]);
    setPriceValue('');
    setQuantityValue(1);
  }

  //* * პროდუქტის წაშლა */

  function deleteProduct(id) {
    const updatedProducts = goods.filter((item, index) => {
      return index !== id;
    });
    setGoods(updatedProducts);
  }

  //* * დეკლარირება */

  const changeShipperMutation = useMutation(
    (declarationDetails) => {
      if (privateParcel) {
        return apiRequest(
          {
            command: 'declare_tracking',
            sessionId: localStorage.getItem('token')
          },
          {
            packageId: parcel && parcel.package_id,
            shipper: -2,
            declarationDetails,
            shipperName: privateShipperName,
            country: selectedCountryData.name
          }
        );
      } else {
        return apiRequest(
          {
            command: 'declare_tracking',
            sessionId: localStorage.getItem('token')
          },
          {
            packageId: parcel && parcel.package_id,
            shipper: privateParcel ? -2 : shipper.id,
            declarationDetails,
            country: selectedCountryData.name
          }
        );
      }
    },
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          handleClose();
        } else if (response.data.status === 'RE_DECLARE_PACKAGE') {
          setAlert(['error', t(response.data.message)]);
          handleClose();
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  const refreshTrackings = async () => {
    try {
      await changeShipperMutation.mutateAsync(JSON.stringify(goods));
      queryClient.invalidateQueries('getAwaiting');
      dispatch(getAwaitingAsyncThunk());
      queryClient.invalidateQueries('getUsaWarehouse');
      queryClient.invalidateQueries('getPending');
      queryClient.invalidateQueries('getDeclaration');
      handleClose();
    } catch (error) {
      console.log('error', error);
    }
  };

  function getTitle(product) {
    let title;
    getProductCategories?.filter((category) => {
      if (category.code === product.code) {
        title = category[t('_key_desc')];
      }
      return title;
    });
    return title;
  }

  const disableDropdown = () => {
    if (goods?.length === 0) {
      return false;
    }
    if (goods?.length >= 1) {
      return true;
    } else if (!(packageCountry === 'UNKNOWN')) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='Declaration sideBarChildComponent'>
      <h3 className='SectionTitle sidebarTitle'>{t('_key_declare_package')}</h3>
      <div className='sidebar-child-scroll'>
        <div className='d-flex justify-content-between align-items-stretch'>
          {parcel.tracking && <NonChangablePackageDetail icon={<TrackingIcon />} title={t('_TRACKING_CODE')} info={parcel.tracking} />}
        </div>
        <Toggle
          fontStyle='customToggleFont'
          name='insurance'
          checked={privateParcel}
          text={t('_PERSONAL_PACKAGE')}
          onChange={() => {
            setPrivateParcel(!privateParcel);
          }}
        />
        {shipper && (
          <div className='my-4'>
            {privateParcel ? (
              <div className='position-relative font-size-14 search-container'>
                <input
                  placeholder={t('_PROVIDE_SHIPPER_NAME')}
                  className='search shipperName'
                  type='text'
                  value={privateShipperName}
                  onChange={(e) => setPrivateShipperName(e.target.value)}
                />
              </div>
            ) : shipper.name ? (
              error ? (
                <ChangablePackageDetails
                  editable={true}
                  icon={<GlobeIcon />}
                  title={shipper.name}
                  disabled={privateParcel ? 'disabled' : ''}
                  editableFunction={
                    privateParcel
                      ? null
                      : () => {
                          setOpenShippers(!openShippers);
                        }
                  }
                />
              ) : loading ? (
                <ChangablePackageDetails
                  editable={true}
                  icon={<GlobeIcon />}
                  title={shipper.name}
                  disabled={privateParcel ? 'disabled' : ''}
                  editableFunction={
                    privateParcel
                      ? null
                      : () => {
                          setOpenShippers(!openShippers);
                        }
                  }
                />
              ) : image ? (
                <ChangablePackageDetails
                  src={image}
                  title={shipper.name}
                  disabled={privateParcel ? 'disabled' : ''}
                  editableFunction={
                    privateParcel
                      ? null
                      : () => {
                          setOpenShippers(!openShippers);
                        }
                  }
                  editable
                />
              ) : (
                <ChangablePackageDetails
                  editable={true}
                  icon={<GlobeIcon />}
                  title={shipper.name}
                  disabled={privateParcel ? 'disabled' : ''}
                  editableFunction={
                    privateParcel
                      ? null
                      : () => {
                          setOpenShippers(!openShippers);
                        }
                  }
                />
              )
            ) : (
              <AddNew
                icon={<GlobeIcon />}
                text={t('_key_choose_website')}
                onClick={privateParcel ? null : () => setOpenShippers(!openShippers)}
                disabled={privateParcel ? 'disabled' : ''}
              />
            )}
          </div>
        )}
        <div className='my-4 packageProductsDiv'>
          <div className='d-flex justify-content-between align-items-center mb-2 flex-wrap'>
            <h3 className='packageProductDivTitle'>{t('_key_products')}</h3>
            <div className={`${JSON.stringify(productPriceTotal).length >= 7 ? 'BigTotalPrice' : ''} d-flex align-items-center w-100 justify-content-between`}>
              <span className='packagePrice'>{t('_key_product_sum')}</span>
              <p className='packageTotalPrice'>{getAmountWithCurrency(productPriceTotal.toFixed?.(2), selectedCountryData?.label)}</p>
            </div>
          </div>
          {goods?.length > 0 ? (
            <div className='productsContainer'>
              {goods.map((product, index) => {
                return (
                  <div key={index}>
                    <ChangablePackageDetails
                      title={getTitle(product) || product.description}
                      icon={<InboxIcon />}
                      productCode={product.code}
                      content={getAmountWithCurrency((product?.unit_price * product?.unit_count)?.toFixed?.(2), selectedCountryData?.label)}
                      deletable={true}
                      deleteFunction={() => deleteProduct(index)}
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
          <AddNew
            text={t('_key_add_new_product')}
            onClick={() => {
              setOpenProducts(true);
            }}
          />
        </div>
      </div>
      <div className='sidebarButtonDiv'>
        <Button
          isLoading={changeShipperMutation?.isLoading}
          text={t('_key_declaration')}
          btnStyle='button-primary d-flex justify-content-center declarationBtn'
          handleClick={() => {
            if (shipper?.name || shipper === -2) {
              const declarationThreshold = JSON?.parse(localStorage.getItem('declaration_threshold'));
              if (productPriceTotal >= declarationThreshold?.[selectedCountryData?.name]) {
                setOpenConfirmation(true);
              } else {
                refreshTrackings();
              }
            } else {
              setAlert(['error', t('_INVALID_SHIPPER')]);
            }
          }}
        />
      </div>
      <Modal showModal={openConfirmation} setShowModal={setOpenConfirmation}>
        <div className='d-flex flex-column'>
          <p className='modalText'>{t('_DECLARE_TRACKING_TOTAL_AMOUNT_CONFIRMATION').replace('$#{AMOUNT}', `${selectedCountryData?.label} ` + productPriceTotal.toFixed(2))}</p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button
              handleClick={() => {
                refreshTrackings();
                setOpenConfirmation(false);
              }}
              type='button'
              btnStyle='bg-orange me-4 fill-balance-btn'
              text={t('_YES')}
              color='color-white'
            />
            <Button
              handleClick={() => {
                setOpenConfirmation(false);
              }}
              type='button'
              btnStyle='bg-orange fill-balance-btn secondary-btn-bg'
              text={t('_NO')}
              color='color-white'
            />
          </div>
        </div>
      </Modal>
      <SideBar openSidebar={openShippers} setOpenSidebar={setOpenShippers} ref={shipperRef} backIcon>
        {openShippers ? (
          <Shippers
            parcel={parcel}
            declarationDetail={declarationDetails || {}}
            chooseShipper={setShipperActiveBox}
            chooseShipperItem={setShipperActive}
            shipperSearch={search}
            shippersList={shippersList}
            shippersLoading={shippersLoading}
          />
        ) : null}
      </SideBar>
      <SideBar openSidebar={openProducts} setOpenSidebar={setOpenProducts} ref={productsRef} backIcon>
        {openProducts ? (
          <AddProduct
            priceValue={priceValue}
            quantityValue={quantityValue}
            onChange={(e) => setPriceValue(e.target.value)}
            onQuantityChange={(e) => setQuantityValue(e.target.value)}
            onClick={changeProducts}
            buttonClick={() => setOpenProducts(false)}
            dropdownDisabled={disableDropdown()}
            selectedCountryData={selectedCountryData}
            setSelectedCountryData={setSelectedCountryData}
            isAwaiting={isAwaiting}
            noDropDown={noDropDown}
            // showCurrencyOnly={goods.length > 0}
            showCurrencyOnly={true}
          />
        ) : null}
      </SideBar>
    </div>
  );
}
