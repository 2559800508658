import React, { useEffect, useState } from 'react';
import { ChatU2GIcon, CloseModalWhiteIcon } from '../../assets/icons';
import './Chat.css';
import { ChatWindow } from './ChatWindow/ChatWindow';

export const Chat = () => {
  const [isOpen, setIsOpen] = useState(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      {(isOpen === true || isOpen === false) && (
        <div className='u2g-chat'>
          <div className='u2g-chat-button' onClick={toggleChat}>
            {isOpen ? <CloseModalWhiteIcon className='u2g-chat-button-icon close-icon' /> : <ChatU2GIcon className='u2g-chat-button-icon' />}
          </div>
          <div className={`u2g-chat-window ${isOpen ? 'u2g-show' : ''}`}>{<ChatWindow isOpen={isOpen} setIsOpen={setIsOpen} />}</div>
        </div>
      )}
    </>
  );
};
