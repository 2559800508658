import { createSlice } from '@reduxjs/toolkit';
import { getGeorgianDateTime } from '../../utils/utils';
import { sendFileAsyncThunk, sendMessageAsyncThunk } from '../thunks/chatbot.thunk';

const initialState = {
  messages: [],
  loading: false,
  recordingAudio: false,
  context: 'main'
};

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    clearChatbot: (state, action) => {
      state.messages = [];
    },
    setRecordingAudio: (state, action) => {
      state.recordingAudio = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessageAsyncThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendMessageAsyncThunk.fulfilled, (state, action) => {
        state.messages.push({ ...action.payload.message, datetime: getGeorgianDateTime() });
        state.loading = false;
        state.context = action.payload.context;
      })
      .addCase(sendFileAsyncThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendFileAsyncThunk.fulfilled, (state, action) => {
        console.log('SLICE', action.payload);
        state.messages.push({ ...action.payload.message, datetime: getGeorgianDateTime() });
        state.loading = false;
        state.context = action.payload.context;
      });
  }
});

export const chatbotSliceActions = chatbotSlice.actions;
export default chatbotSlice.reducer;
