// hooks
import React, { useContext, useState } from 'react';

// components
import BurgerMenu from './BurgerMenu';
import { Link } from 'react-router-dom';
import SmallIcon from '../../SmallIcon';
import GlobalContext from '../../GlobalContext';

// assets
import { NotificationIcon } from '../../../assets/icons';

const ResponsiveNavbar = () => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const { token } = useContext(GlobalContext);

  return (
    <div className="responsive-show responsive-navbar">
      <div className="d-flex align-items-center">
        {token && (
          <div className="mx-2">
            <Link to="/notifications">
              <SmallIcon
                bg="icon-dark-bg notification-icon"
                alt="notification"
                imgStyle="responsive-notification-icon"
                img={<NotificationIcon />}
              />
            </Link>
          </div>
        )}
        <div>
          <div
            onClick={() => setShowBurgerMenu(true)}
            className="burger-menu-btn"
          >
            <span />
            <span />
          </div>
          <BurgerMenu
            showBurgerMenu={showBurgerMenu}
            setShowBurgerMenu={setShowBurgerMenu}
          />
        </div>
      </div>
      <div />
    </div>
  );
};

export default ResponsiveNavbar;
