import React from 'react';
import './Radio.scss';
import { TickIcon } from '../../assets/icons';

const Radio = ({ selected, text, value, name, ...props }) => {
  return (
    <div className="d-flex align-items-center">
      <div>
        <label className="custom-radio-btn">
          <input
            value={value}
            checked={selected}
            type="radio"
            name={name}
            {...props}
          />
          <div className={`checkmark checkmark-checked-${selected}`}>
            <div className="d-flex justify-content-center checkbox-div align-items-center">{selected && <TickIcon />}</div>
          </div>
        </label>
      </div>
      <span className="font-size-14 color-third-grey bold">{text}</span>
    </div>
  );
};

export default Radio;
